import type {
  ApiAdapterRequestHeaders,
  IApiAdapters,
  RequestManager,
} from '@/adapters/api/__base';

import {
  DashboardAvailableDataSource,
  DashboardUpstreamConnection,
} from '@cigro/data-connection/model';
import invariant from 'tiny-invariant';
import { z } from 'zod';

import { BaseApiAdapterClass } from '@/adapters/api/__base';
import { ApiSuccessResponse } from '@/common.type';
import { zDateSchema } from '@/models/common';
import {
  UserDtoSchema,
  zDashboardGuestUserDto,
  zDashboardPermissions,
  zDashboardUserDto,
} from '@/models/user';
import { zFilterItem } from '@/models/view';
import { serializeArrayData, serializeData } from '@/utils/data';

import { clientHttp, serverHttp } from './axios';
import { zQueryParameter } from './data-transformation';
import { createFetchRequestManager } from './fetch-http-client';
import { dashboardUrl } from './url-string';

class DashboardApiAdapterClass
  extends BaseApiAdapterClass
  implements IApiAdapters<DashboardApiAdapterClass>
{
  constructor(rm: RequestManager) {
    super(rm);
  }

  private clone(): DashboardApiAdapterClass {
    return new DashboardApiAdapterClass(this.requestManager);
  }

  buildWithRequestHeaders(
    headers: ApiAdapterRequestHeaders,
  ): DashboardApiAdapterClass {
    const cloned = this.clone();
    cloned.setRequestHeaders(headers);
    return cloned;
  }

  async create(teamId: string, payload: MutateDashboardPayload) {
    invariant(teamId, 'no team id provided');

    const url = '/teams/' + teamId + '/dashboards';

    const dashboard = await this.request<DashboardDto, MutateDashboardPayload>(
      url,
      'post',
      payload,
    );
    return serializeData(dashboard, DashboardDtoSchema);
  }

  async changeOrder(teamId: string, payload: ChangeDashboardOrdersPayload[]) {
    invariant(teamId, 'no team id provided');

    const url = '/teams/' + teamId + '/dashboards/orders';

    const data = await this.request<
      ChangeDashboardOrdersResponse[],
      ChangeDashboardOrdersPayload[]
    >(url, 'put', payload);

    return serializeArrayData(data ?? [], ChangeDashboardOrdersResponseSchema);
  }

  async getAll(teamId: string): Promise<DashboardDto[]> {
    invariant(teamId, 'no team id provided');

    const dashboards = await this.get<DashboardDto[]>(
      '/teams/' + teamId + '/dashboards',
    );

    if (dashboards == null) return [];

    return serializeArrayData(dashboards, DashboardDtoSchema);
  }

  async getDetail(
    teamId: string,
    dashboardId: string,
  ): Promise<DashboardDto | null> {
    invariant(teamId, 'no team id provided');

    const dashboard = await this.get<DashboardDto>(
      '/teams/' + teamId + '/dashboards/' + dashboardId,
    );

    return serializeData(dashboard, DashboardDtoSchema);
  }

  async getTemplates(teamId: string): Promise<TemplateDto[]> {
    invariant(teamId, 'no team id provided');

    const templates = await this.get<TemplateDto[]>(
      '/teams/' + teamId + '/dashboards/templates',
    );

    if (templates == null) return [];

    return serializeArrayData(templates, TemplateDtoSchema);
  }

  async saveLastSeenAt(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    await this.request(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/save-last-seen',
      'post',
    );
  }

  async duplicate(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    const data = await this.request<DashboardDto>(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/duplicate',
      'post',
    );

    return serializeData(data, DashboardDtoSchema);
  }

  async like(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');

    await this.request<string>(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/likes',
      'post',
    );

    return null;
  }

  async unlike(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');

    await this.request(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/likes',
      'delete',
    );

    return null;
  }

  async remove(teamId: string, dashboardId: string): Promise<null> {
    invariant(teamId, 'no team id provided');
    await this.request(
      '/teams/' + teamId + '/dashboards/' + dashboardId,
      'delete',
    );
    return null;
  }

  async update(
    teamId: string,
    dashboardId: string,
    payload: MutateDashboardPayload,
  ) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    const url = '/teams/' + teamId + '/dashboards/' + dashboardId;

    const dashboard = await this.request<DashboardDto, MutateDashboardPayload>(
      url,
      'put',
      payload,
    );

    return serializeData(dashboard, DashboardDtoSchema);
  }

  async getSharingSetting(
    teamId: string,
    dashboardId: string,
  ): Promise<GetDashboardShareSettingsResponse | null> {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    const data = await this.get<GetDashboardShareSettingsResponse>(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/sharing',
    );

    return serializeData(data, GetDashboardShareSettingsResponseSchema);
  }

  async updateSharingSetting(
    teamId: string,
    dashboardId: string,
    payload: UpdateDashboardShareSettingPayload,
  ): Promise<void> {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    await this.request(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/sharing',
      'put',
      payload,
    );
  }

  async filtersHealthCheck(
    teamId: string,
    filters: DashboardHealthCheckInput,
  ): Promise<DashboardHealthCheckResult | null> {
    invariant(teamId, 'no team id provided');

    return await this.request<
      DashboardHealthCheckResult,
      DashboardHealthCheckInput
    >('/teams/' + teamId + '/dashboards/filters-health-check', 'post', filters);
  }

  publish(
    teamId: string,
    dashboardId: string,
    payload: DashboardPublishingUpdate,
  ) {
    return this.request<ApiSuccessResponse, DashboardPublishingUpdate>(
      dashboardUrl.publishing(teamId, dashboardId),
      'put',
      payload,
    );
  }

  async generateReadOnlyToken(dashboardId: string) {
    const res = await this.request<
      {
        dashboard_id: string;
        team_id: string;
        team_name: string;
        access_token: string;
        access_token_expires_at: string;
      },
      void
    >(dashboardUrl.generateReadOnlyToken(dashboardId), 'post');
    return res;
  }

  async getPublicDashboardDetail(
    teamId: string,
    dashboardId: string,
  ): Promise<DashboardDto | null> {
    invariant(teamId, 'no team id provided');

    const dashboard = await this.get<DashboardDto>(
      '/teams/' + teamId + '/dashboards/' + dashboardId,
    );

    return dashboard;
  }

  async getUpstreamConnections(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    const data = await this.get<DashboardUpstreamConnection[]>(
      '/teams/' +
        teamId +
        '/dashboards/' +
        dashboardId +
        '/upstream-connections',
    );

    return data ?? [];
  }

  async getAvailableDataSources(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    const data = await this.get<DashboardAvailableDataSource[]>(
      '/teams/' +
        teamId +
        '/dashboards/' +
        dashboardId +
        '/available-connections-for-template',
    );

    return data ?? [];
  }

  async linkConnection(
    teamId: string,
    dashboardId: string,
    connectionId: string,
  ) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');
    invariant(connectionId, 'no connection id provided');

    await this.request(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/link-connection',
      'post',
      { connection_id: connectionId },
    );
  }

  async unlinkConnection(
    teamId: string,
    dashboardId: string,
    connectionId: string,
  ) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');
    invariant(connectionId, 'no connection id provided');

    await this.request(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/unlink-connection',
      'post',
      { connection_id: connectionId },
    );
  }

  async getPreviewSubscriptionContent(teamId: string, dashboardId: string) {
    invariant(teamId, 'no team id provided');
    invariant(dashboardId, 'no dashboard id provided');

    const data = await this.get<string[]>(
      '/teams/' + teamId + '/dashboards/' + dashboardId + '/_preview_content',
    );

    return data ?? [];
  }
}

/**
 * @deprecated
 */
export const DashboardApiAdapter = Object.freeze(
  new DashboardApiAdapterClass(clientHttp),
);

/**
 * @deprecated
 */
export const DashboardServerApiAdapter = Object.freeze(
  new DashboardApiAdapterClass(serverHttp),
);

export const DashboardApi = {
  onBrowser: () => new DashboardApiAdapterClass(clientHttp),
  onServer: () => new DashboardApiAdapterClass(serverHttp),
  onServerFetch: (init?: RequestInit) =>
    new DashboardApiAdapterClass(createFetchRequestManager(init)),
};

export const EXPIRATION_PRESET = z.enum([
  'ONE_HOUR',
  'ONE_DAY',
  'ONE_WEEK',
  'ONE_MONTH',
  'CUSTOMIZED',
]);
export type ExpirationPreset = z.infer<typeof EXPIRATION_PRESET>;

export const zPublishingConfig = z.object({
  expiration_preset: EXPIRATION_PRESET,
  expires_at: z.string().nullish(),
});
export type PublishingConfig = z.infer<typeof zPublishingConfig>;

export const zColumnFilters = z.object({
  data_model_id: z.string(),
  dataset_id: z.string(),
  column_filter: zFilterItem,
});
export type ColumnFilters = z.infer<typeof zColumnFilters>;

export const DashboardFilterSchema = z.object({
  id: z.string().nullish(),
  name: z.string(),
  is_active: z.boolean().nullable().optional(),
  logical_operator: z.enum(['AND', 'OR']).nullable().optional(),
  column_filters: z.array(zColumnFilters),
  applied_widget_ids: z.array(z.string()).nullish(),
});
export type DashboardFilterItem = z.infer<typeof DashboardFilterSchema>;

export const DashboardConfigSchema = z.object({
  filters: z.array(DashboardFilterSchema).nullish(),
  is_filter_active: z.boolean().nullish(),
  publishing: zPublishingConfig.nullish(),
});
export type DashboardConfig = z.infer<typeof DashboardConfigSchema>;

export const DashboardDtoSchema = z.object({
  id: z.string(),
  team_id: z.string(),
  name: z.string(),
  owner_id: z.string(),
  order: z.number().int().nullable().optional(),
  config: DashboardConfigSchema.nullable().optional(),
  liked_order: z.number().int().nullable().optional(),
  is_public: z.boolean(),
  is_published: z.boolean(),
  has_liked: z.boolean().nullable().optional(),
  created_at: zDateSchema.optional(),
  updated_at: zDateSchema.optional(),
  permission: zDashboardPermissions.nullish(),
  description: z.string().nullable().optional(),
  creator: UserDtoSchema.nullish(),
  updated_by: UserDtoSchema.nullish(),
  last_seen_at: zDateSchema.optional(),
  thumbnail_url: z.string(),
  dashboard_template_id: z.string().nullish(),
});

export type DashboardDto = z.infer<typeof DashboardDtoSchema>;
export const TemplateDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  thumbnail_url: z.string().nullish(),
  created_at: zDateSchema.optional(),
  updated_at: zDateSchema.optional(),
});

export type TemplateDto = z.infer<typeof TemplateDtoSchema>;
const zDashboardPublishingUpdate = z.object({
  is_published: z.boolean(),
  publishing: zPublishingConfig.nullish(),
});

export type DashboardPublishingUpdate = z.infer<
  typeof zDashboardPublishingUpdate
>;
export const UpdateDashboardShareSettingPayloadSchema = z.object({
  is_public: z.boolean(),
  new_shared_ids: z.array(z.string()).default([]),
  revoked_ids: z.array(z.string()).default([]),
  member_id_to_permission: z.record(zDashboardPermissions),
  new_guest_emails: z.array(z.string()).default([]),
  revoked_guest_emails: z.array(z.string()).default([]),
  expected_dashboard_url: z.string().nullish(),
});
type UpdateDashboardShareSetting = z.infer<
  typeof UpdateDashboardShareSettingPayloadSchema
>;
export type UpdateDashboardShareSettingPayload =
  Partial<UpdateDashboardShareSetting>;
export const ChangeDashboardOrdersPayloadSchema = z.object({
  id: z.string(),
  order: z.number().int().min(1).nullish(),
  liked_order: z.number().int().min(1).nullish(),
});

export type ChangeDashboardOrdersPayload = z.infer<
  typeof ChangeDashboardOrdersPayloadSchema
>;
export const ChangeDashboardOrdersResponseSchema = z.object({
  id: z.string(),
  order: z.number().int().nullable().optional(),
  liked_order: z.number().int().nullable().optional(),
});
export type ChangeDashboardOrdersResponse = z.infer<
  typeof ChangeDashboardOrdersResponseSchema
>;

export const zDashboardHealthCheckInput = z.array(
  z.object({
    filter: DashboardFilterSchema,
    is_local: z.boolean(),
  }),
);

export type DashboardHealthCheckInput = z.infer<
  typeof zDashboardHealthCheckInput
>;
export const zDashboardHealthCheckResult = z.array(
  z.object({
    filter: DashboardFilterSchema,
    is_healthty: z.boolean().nullish(),
    suggested_filter: DashboardFilterSchema.nullish(),
    is_local: z.boolean(),
  }),
);

export type DashboardHealthCheckResult = z.infer<
  typeof zDashboardHealthCheckResult
>;
export const GetDashboardShareSettingsResponseSchema = z.object({
  is_public: z.boolean(),
  shared_members: z
    .array(z.union([zDashboardUserDto, zDashboardGuestUserDto]))
    .default([]),
});

export type GetDashboardShareSettingsResponse = z.infer<
  typeof GetDashboardShareSettingsResponseSchema
>;
const MutateDashboardPayloadSchema = z.object({
  name: z.string().optional(),
  is_public: z.boolean().optional(),
  config: DashboardConfigSchema.optional(),
  description: z.string().nullable().optional(),
  last_seen_at: z.date().optional(),
});

export type MutateDashboardPayload = z.infer<
  typeof MutateDashboardPayloadSchema
>;
