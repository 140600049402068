import type { RequestManager } from '@/adapters/api/__base';

import { z } from 'zod';

import { BaseApiAdapterClass } from '@/adapters/api/__base';

import { clientHttp, invitationHttp, serverHttp } from './axios';
import { createFetchRequestManager } from './fetch-http-client';
import { templateUrl } from './url-string';

/**
 * SCHEMA
 */

const zDataSource = z.object({
  id: z.string(),
  name: z.string(),
  icon_url: z.string(),
  thumbnail_url: z.string().optional(),
});

const zMetadata = z.object({
  description: z.string(),
  metrics: z.array(z.string()),
  expectations: z.array(z.string()),
});

const zTemplate = z.object({
  id: z.string(),
  thumbnail_url: z.string(),
  preview_image_urls: z.array(z.string()),
  name: z.string(),
  is_active: z.boolean(),
  in_use: z.boolean(),
  data_sources: z.array(zDataSource),
  details: zMetadata,
  usecases: z.array(z.string()),
  preview_dashboard_id: z.string(),
});

export const zTemplateUsecase = z.enum(['Marketing', 'Agency', 'E-commerce']);
export type TemplateUsecase = z.infer<typeof zTemplateUsecase>;

const zTemplateCategory = z.object({
  usecases: z.array(zTemplateUsecase).nullish(),
});

export type Template = z.infer<typeof zTemplate>;
export type TemplateCategory = z.infer<typeof zTemplateCategory>;

const zTemplateListResponse = z.object({
  results: z.array(zTemplate),
  total_rows: z.number(),
});
export type TemplateListResponse = z.infer<typeof zTemplateListResponse>;

export const zDashboardTemplateParams = z.object({
  team_id: z.string().nullish(),
  search_keyword: z.string().nullish(),
  usecases: zTemplateUsecase.nullish(),
  size: z.number().nullish(),
  offset: z.number().nullish(),
});
export type DashboardTemplateParams = z.infer<typeof zDashboardTemplateParams>;

/**
 * ADAPTER
 */
class TemplateApiAdapter extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  async getCategories() {
    const data = await this.get<TemplateCategory>(
      '/dashboard-templates/metadata',
    );

    return data?.usecases ?? [];
  }

  getAll(params: DashboardTemplateParams) {
    return this.get<TemplateListResponse>(templateUrl.all(), params);
  }

  getDetail(templateId: string, teamId?: string) {
    return this.get<Template>(templateUrl.detail(templateId, teamId));
  }

  requestNewTemplate(
    teamId: string,
    payload: { subject: string; description: string },
  ) {
    return this.request(templateUrl.request(), 'post', payload, {
      team_id: teamId,
    });
  }
}

export const TemplateApi = {
  onBrowser: () => new TemplateApiAdapter(clientHttp),
  onAnonymousBrowser: () => new TemplateApiAdapter(invitationHttp),
  onServer: () => new TemplateApiAdapter(serverHttp),
  onServerFetch: (fetchOptions?: RequestInit) =>
    new TemplateApiAdapter(createFetchRequestManager(fetchOptions)),
};
