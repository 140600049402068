import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { Divider } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { signOut } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { type FallbackProps } from 'react-error-boundary';

import { useGlobalMe } from '@/hooks/use-global-me';
import { AppRoutes } from '@/routes';
import { toastSuccess } from '@/utils/notification';
import DashboardFill from '@static/assets/empty-page/dashboard-fill.png';

import { BackToDashboardButton } from './components/BackToDashboardButton';

export const DashboardPermissionErrorPage: React.FC<
  Omit<FallbackProps, 'error'>
> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'error-page',
  });

  const { t: tNavBar } = useTranslation('common', {
    keyPrefix: 'navbar',
  });

  const { me } = useGlobalMe();

  return (
    <Stack width="100%" height="100vh">
      <Container maxWidth="md" sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          //
        >
          <Stack width="400px" gap="16px" alignItems="center">
            <Stack
              p="6px"
              borderRadius="99px"
              bgcolor="neutralV2.6"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)"
              sx={{ width: 'fit-content' }}
            >
              <Image
                src={DashboardFill}
                height="18"
                width="18"
                alt="No dashboard permission"
                priority
              />
            </Stack>
            <Stack gap="12px">
              <Typography variant="h2" color="neutralV2.0" textAlign="center">
                {t('access-denied!')}
              </Typography>
              <Typography
                variant="body2"
                textAlign="center"
                color="neutralV2.2"
              >
                {t("you-don't-have-permissions-to-view-this-dashboard")}
              </Typography>
            </Stack>
            <Stack
              width="100%"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              gap="8px"
              //
            >
              <BackToDashboardButton resetErrorBoundary={resetErrorBoundary} />
            </Stack>
            <Divider
              component="span"
              color="neutralV2.5"
              sx={{ width: '100%' }}
            />
            <Stack>
              {me ? (
                <Typography
                  variant="body2"
                  color="neutralV2.2"
                  textAlign="center"
                  mb="4px"
                >
                  {t("you're-currently-signed-in-as")} <b>{me.email}</b>
                </Typography>
              ) : null}

              <Typography
                variant="body2"
                color="neutralV2.2"
                textAlign="center"
              >
                {t('you-may-want-to')}&nbsp;
                <Typography
                  component="u"
                  variant="body2"
                  onClick={() => {
                    toastSuccess(tNavBar('sign-out-success'));
                    signOut({
                      redirect: true,
                      callbackUrl: AppRoutes.SIGNIN,
                    });
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  {t('login-with-different-account')}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
