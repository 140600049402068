import { z } from 'zod';

import { zCustomColumnConfig, zDayOfWeek } from '@/models/common';
import { makeSafeSchema } from '@/utils/data';

// =================== GENERAL SCHEMA ======================
export const zPrimaryColumnType = z.enum([
  'TEXT',
  'NUMBER',
  'DATETIME',
  'BOOLEAN',
]);
export type PrimaryColumnType = z.infer<typeof zPrimaryColumnType>;

export const zArrayColumnType = z.enum([
  'ARRAY_TEXT',
  'ARRAY_NUMBER',
  'ARRAY_BOOLEAN',
  'ARRAY_DATETIME',
]);
export type ArrayColumnType = z.infer<typeof zArrayColumnType>;

export const zColumnType = z.union([zPrimaryColumnType, zArrayColumnType]);

export type ColumnType = z.infer<typeof zColumnType>;

const zNeedValueCompareOption = z.enum([
  // 'YESTERDAY',
  // 'LAST_WEEK',
  // 'LAST_MONTH',
  'DAY',
  'WEEK',
  'MONTH',
  'QUARTER',
  'YEAR',
]);

export type NeedValueCompareOption = z.infer<typeof zNeedValueCompareOption>;

const zNoValueCompareOption = z.enum(['RIGHT_BEFORE']);

export type NoValueCompareOption = z.infer<typeof zNoValueCompareOption>;

const zRangeCompareOption = z.enum(['CUSTOM_DATE']);

export type RangeCompareOption = z.infer<typeof zRangeCompareOption>;

const zCompareReferenceType = z.union([
  zNeedValueCompareOption,
  zRangeCompareOption,
  zNoValueCompareOption,
]);

export type CompareReferenceType = z.infer<typeof zCompareReferenceType>;

export const zViewType = z.enum([
  'TABLE',
  'BAR_CHART',
  'PIE_CHART',
  'LINE_CHART',
  'BOARD',
  'PIVOT_TABLE',
  'GALLERY',
  'GOAL',
  'BAR_AND_LINE_COMBINED_CHART',
  'RANK_CHART',
]);

export type ViewType = z.infer<typeof zViewType>;

// =================== FILTER SCHEMA ======================
export const zTextOperator = z.enum(['IS', 'IS_NOT', 'CONTAIN', 'NOT_CONTAIN']);
export const zFilterOperator = z.enum(['AND', 'OR']);

export const zNumberOperator = z.enum([
  'GREATER_THAN',
  'LESS_THAN',
  'EQUAL',
  'GREATER_OR_EQUAL',
  'LESS_OR_EQUAL',
]);

export const zTimeRange = z.enum([
  'TODAY',
  'YESTERDAY',
  'THIS_WEEK',
  'LAST_WEEK',
  'THIS_QUARTER',
  'LAST_QUARTER',
  'THIS_MONTH',
  'LAST_MONTH',
  'THIS_YEAR',
  'LAST_YEAR',
  'RECENT_7_DAYS',
  'RECENT_14_DAYS',
  'RECENT_30_DAYS',
  'RECENT_3_MONTHS',
  'RECENT_6_MONTHS',
  'RECENT_12_MONTHS',
  'WEEK_TO_DATE',
  'MONTH_TO_DATE',
  'QUARTER_TO_DATE',
  'YEAR_TO_DATE',
  'WEEK_TO_YESTERDAY',
  'MONTH_TO_YESTERDAY',
  'QUARTER_TO_YESTERDAY',
  'YEAR_TO_YESTERDAY',
]);

export type TextOperator = z.infer<typeof zTextOperator>;

export type FilterOperator = z.infer<typeof zFilterOperator>;

export type NumberOperator = z.infer<typeof zNumberOperator>;

export type TimeRange = z.infer<typeof zTimeRange> | 'CUSTOM';

const zBooleanOption = z.object({
  column_type: z.literal('BOOLEAN'),
  value: z.boolean(),
});

const zNumberOption = z.object({
  column_type: z.literal('NUMBER'),
  value: z.number().nullable(),
  operator: zNumberOperator,
});

const zFilterItemTextValue = z.string().nullable();

export type FilterItemTextValue = z.infer<typeof zFilterItemTextValue>;

const zTextOption = z.union([
  z.object({
    column_type: z.literal('TEXT'),
    value: zFilterItemTextValue,
    operator: z.enum([
      zTextOperator.enum.CONTAIN,
      zTextOperator.enum.NOT_CONTAIN,
    ]),
  }),
  z.object({
    column_type: z.literal('TEXT'),
    value: z.array(zFilterItemTextValue).nullable(), // NOTE: nullable value
    operator: z.enum([zTextOperator.enum.IS, zTextOperator.enum.IS_NOT]),
  }),
]);

const zBaseDateOption = z.object({
  column_type: z.literal('DATETIME'),
  first_day_of_week: zDayOfWeek.default('MONDAY'),
});
const zDateOption = z.union([
  zBaseDateOption.extend({
    time_range: z.literal('CUSTOM'),
    from_time: z.string(),
    to_time: z.string(),
  }),
  zBaseDateOption.extend({
    time_range: zTimeRange,
    from_time: z.null(),
    to_time: z.null(),
  }),
]);

const zFilterOption = z.union([
  zNumberOption,
  zTextOption,
  zDateOption,
  zBooleanOption,
]);

export type FilterOption = z.infer<typeof zFilterOption>;

export const zDateFilterItem = z.intersection(
  z.object({
    column_name: z.string(),
  }),
  zDateOption,
);

export type DateFilterItem = z.infer<typeof zDateFilterItem>;

export const zFilterItem = z.intersection(
  z.object({
    column_name: z.string(),
  }),
  zFilterOption,
);

export type FilterItem = z.infer<typeof zFilterItem>;

// =================== COMPOSITE FILTER SCHEMA ======================

export const zAdvancedFilter = z.object({
  name: z.string(),
  is_active: z.boolean().nullable().optional(),
  logical_operator: z.enum(['AND', 'OR']).nullable().optional(),
  column_filters: z.array(zFilterItem).optional().nullable(),
});

export type AdvancedFilter = z.infer<typeof zAdvancedFilter>;

// =================== SORT SCHEMA ======================

export const zSortOption = z.enum(['ASC', 'DESC']);

export type SortOption = z.infer<typeof zSortOption>;

export const zSortItem = z.object({
  column_name: z.string(),
  column_type: zColumnType,
  option: zSortOption,
});

export type SortItem = z.infer<typeof zSortItem>;

// =================== GROUP SCHEMA ======================
export const zGroupItem = z.object({
  column_name: z.string(),
  column_type: zColumnType,
});

export const zAggregateType = z.enum(
  [
    'SUM',
    'MAX',
    'MIN',
    'AVG',
    'COUNT',
    'COUNT_DISTINCT',
    'COUNT_EMPTY',
    'COUNT_NOT_EMPTY',
    'FORMULA',
    'FIRST_ITEM',
    'AGG', // NOTE: AGG means the column itself is aggreated, no need any other aggregation functions.
    'SINGLE_ROW_AGGREGATE',
    'CUMMULATE',
  ],
  {
    description:
      'NOTE: `AGG` type means the column itself is aggreated, no need any other aggregation functions.',
  },
);

export const zDateFormatFn = z.enum([
  'date',
  'week',
  'month',
  'quarter',
  'year',
]);

export type DateFormatFn = z.infer<typeof zDateFormatFn>;

export const zNonAggregationFn = z.enum(['SINGLE_ROW_AGGREGATE']);

export type AggregateType = z.infer<typeof zAggregateType>;

export const zAggregateItem = z.object({
  column_name: z.string(),
  column_type: zColumnType,
  aggregate_type: makeSafeSchema(zAggregateType.optional(), 'COUNT'),
});

export type AggregateItem = z.infer<typeof zAggregateItem>;

export const zGrouping = z.object({
  columns: makeSafeSchema(z.array(zGroupItem), []),
  sub_aggregates: makeSafeSchema(z.array(zAggregateItem).optional(), []),
});

export type GroupItem = z.infer<typeof zGroupItem>;

// =================== COLUMN SCHEMA ======================

export const zColumn = z.object({
  column_name: z.string(),
  column_type: zColumnType.nullable(),
  show: z.boolean().nullish(),
  fixed: z.boolean().nullish(),
});

export type Column = z.infer<typeof zColumn>;

// =================== DIFFERENT CHART OPTION SCHEMA ======================

export const zPeriodicity = z.enum([
  'DAILY',
  'WEEKLY',
  'MONTHLY',
  'QUARTERLY',
  'YEARLY',
]);
export type TimeSeriesPeriod = z.infer<typeof zPeriodicity>;

export const zTimeSeriesOption = z.object({
  periodicity: zPeriodicity,
});

const zComparisonMethod = z.enum(['DIFFERENCE_GAP', 'PERCENTAGE_RATIO']);

export type ComparisonMethod = z.infer<typeof zComparisonMethod>;

const zComparisonMethodByColumn = z.record(zComparisonMethod);

export type ComparisonMethodByColumn = z.infer<
  typeof zComparisonMethodByColumn
>;

export const zCompareToThePastConfig = z.union([
  z.object({
    comparison_method_by_column: zComparisonMethodByColumn.nullish(),
    period_time: zNeedValueCompareOption,
    gap_value: z.number().nullish(),
  }),
  z.object({
    comparison_method_by_column: zComparisonMethodByColumn.nullish(),
    period_time: zNoValueCompareOption,
  }),
  z.object({
    comparison_method_by_column: zComparisonMethodByColumn.nullish(),
    period_time: zRangeCompareOption,
    from_date: z.string().nullish(),
    to_date: z.string().nullish(),
  }),
]);

export type TimeSeriesOption = z.infer<typeof zTimeSeriesOption>;

const zPaletteColors = z.array(z.string());

export type PaletteColors = z.infer<typeof zPaletteColors>;

const zPalette = z.object({ id: z.string(), colors: zPaletteColors.nullish() });

export type Palette = z.infer<typeof zPalette>;

const zColorConfig = z.object({
  palette: zPalette.nullish(),
  by_values: z.record(z.string(), z.string()).nullish(),
  by_series: z.record(z.string(), z.string()).nullish(),
});

export type ColorConfig = z.infer<typeof zColorConfig>;

const zConditionVariant = z.object({
  color: z.string(),
  condition: zFilterOption,
});

export type ConditionVariant = z.infer<typeof zConditionVariant>;

const zCondition = z.object({
  affect_on: z.enum(['ALL', 'EXCEPT', 'ONLY']),
  column: zGroupItem.nullish(),
  variants: z.array(zConditionVariant),
});

export type ViewCondition = z.infer<typeof zCondition>;

export const zCommonConfig = z.object({
  timezone: makeSafeSchema(z.string(), 'UTC'),
  filter: makeSafeSchema(zFilterItem.array(), []),
  advanced_filters: makeSafeSchema(zAdvancedFilter.array(), []),
  grouping: makeSafeSchema(zGrouping, {
    columns: [],
    sub_aggregates: [],
  }),
  display: makeSafeSchema(zColumn.array(), []),
  undisplay: makeSafeSchema(zColumn.array(), []),
  compare_with_past: zCompareToThePastConfig.nullish(),
  time_series: zTimeSeriesOption.nullish(),
  custom_columns: z.record(z.string(), zCustomColumnConfig).nullish(),
  color: zColorConfig.nullish(),
  color_conditions: makeSafeSchema(zCondition.array(), []),
});

export type CommonConfig = z.infer<typeof zCommonConfig>;

const zChartOrientation = z.enum(['VERTICAL', 'HORIZONTAL']);
export type ChartOrientation = z.infer<typeof zChartOrientation>;

export const zCommonChartConfig = z.object({
  type: z.enum(['GROUPED', 'STACKED', 'NORMALIZED']).nullish(),
  sort_option: zSortOption.nullish(),
  orientation: makeSafeSchema(zChartOrientation, 'VERTICAL'),
  show_values: z.boolean().default(false).nullish(),
  rotate_label: z.boolean().default(false).nullish(),
  label_size: z.number().default(6).nullish(),
});
