import { z } from 'zod';

import {
  ApiAdapterRequestHeaders,
  BaseApiAdapterClass,
  type RequestManager,
} from '@/adapters/api/__base';
import {
  DagNodeItem,
  IDagConfig,
  IDataSourceNode,
} from '@/feature-graph/types';
import { zCollectionDataModelColumn } from '@/feature-transformation/model';
import { zArrayColumnType, zPrimaryColumnType } from '@/models/view';
import { serializeData } from '@/utils/data';

import { clientHttp, serverHttp } from './axios';
import { dataModelsURL, dataTransformationUrlMap } from './url-string';

const zSuitableConnection = z.object({
  connection_id: z.string(),
  connection_name: z.string(),
});

const zConnectionsByDataSourceId = z.record(
  z.string(),
  z.array(zSuitableConnection).nullish(),
);

export type SuitableConnection = z.infer<typeof zSuitableConnection>;

// type ConnectionsByDataSourceId = z.infer<typeof zConnectionsByDataSourceId>;

const zTemplateConnectionsResponse = z.object({
  data_source_id_to_suitable_connections: zConnectionsByDataSourceId,
});

export type TemplateConnectionsResponse = z.infer<
  typeof zTemplateConnectionsResponse
>;

const zTemplatePreviewRequest = z.object({
  connection_ids: z.array(z.string()),
});

export type TemplatePreviewRequest = z.infer<typeof zTemplatePreviewRequest>;

const zTemplatePreviewRecord = z.record(
  z.string(),
  z.union([z.string(), z.number(), z.null()]),
);

export type TemplatePreviewRecord = z.infer<typeof zTemplatePreviewRecord>;

export const zBooleanQueryParameter = z.object({
  name: z.string(),
  type: z.literal('BOOLEAN'),
  value: z.boolean(),
});
export type BooleanQueryParameter = z.infer<typeof zBooleanQueryParameter>;

export const zStringQueryParameter = z.object({
  name: z.string(),
  type: zPrimaryColumnType.exclude(['BOOLEAN']),
  value: z.string(),
});
export type StringQueryParameter = z.infer<typeof zStringQueryParameter>;

export const zPrimaryQueryParameter = z.union([
  zBooleanQueryParameter,
  zStringQueryParameter,
]);
export type PrimaryQueryParameter = z.infer<typeof zPrimaryQueryParameter>;

export const ZArrayParamsValue = z.array(
  z.string().or(z.number()).or(z.boolean()),
);
export type ArrayParamsValue = z.infer<typeof ZArrayParamsValue>;

export const zArrayQueryParameter = z.object({
  name: z.string(),
  type: zArrayColumnType,
  value: ZArrayParamsValue,
});
export type ArrayQueryParameter = z.infer<typeof zArrayQueryParameter>;

export const zQueryParameter = z.union([
  zPrimaryQueryParameter,
  zArrayQueryParameter,
]);
export type QueryParameter = z.infer<typeof zQueryParameter>;

export const zQueryExecutionRequest = z.object({
  query: z.string(),
  parameters: z.array(zQueryParameter),
});

export type QueryExecutionRequest = z.infer<typeof zQueryExecutionRequest>;

const zQueryExecutionResponse = z.array(zTemplatePreviewRecord).nullish();

export type QueryExecutionResponse = z.infer<typeof zQueryExecutionResponse>;

const zTemplatePreviewQueryResponse = z.string();

export type TemplatePreviewQueryResponse = z.infer<
  typeof zTemplatePreviewQueryResponse
>;

const zCreateDataModelFromQueryRequest = z.object({
  query: z.string(),
  parent_collection_id: z.string(),
  description: z.string().nullish(),
  id: z.string().nullish(),
  parameters: z.array(zQueryParameter).nullish(),
});

export type CreateDataModelFromQueryRequest = z.infer<
  typeof zCreateDataModelFromQueryRequest
>;

const zDataModelData = z.object({
  id: z.string(),
  team_id: z.string(),
  owner_id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
});

export type DataModelData = z.infer<typeof zDataModelData>;

class DataTransformationApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  private clone() {
    return new DataTransformationApiAdapterClass(this.requestManager);
  }

  buildWithRequestHeaders(headers: ApiAdapterRequestHeaders) {
    const cloned = this.clone();
    cloned.setRequestHeaders(headers);
    return cloned;
  }

  // TEMPLATES

  async getTemplates(teamId?: string | null) {
    const queryParams = new URLSearchParams({
      size: '1000',
      ...(teamId && {
        team_id: teamId,
      }),
    });
    return await this.get<TemplateData[]>(
      dataTransformationUrlMap['templates']() + '?' + queryParams.toString(),
    );
  }

  async getTemplateConnections(teamId: string, templateId: string) {
    return await this.get<TemplateConnectionsResponse>(
      dataTransformationUrlMap['templateConnections'](teamId, templateId),
    );
  }

  async getTemplatePreviewQuery(
    teamId: string,
    templateId: string,
    connectionIds: string[],
  ) {
    return await this.request<
      TemplatePreviewQueryResponse,
      TemplatePreviewRequest
    >(
      dataTransformationUrlMap['templatePreviewQuery'](teamId, templateId),
      'post',
      { connection_ids: connectionIds },
    );
  }

  async executeQuery(teamId: string, payload: QueryExecutionRequest) {
    return await this.request<QueryExecutionResponse, QueryExecutionRequest>(
      dataTransformationUrlMap['executeQuery'](teamId),
      'post',
      payload,
      {},
      {},
      {
        timeout: 10 * 1000 * 60, // 10 mins
      },
    );
  }

  // DATA_MODEL
  async createDataModelFromQuery(
    teamId: string,
    payload: CreateDataModelFromQueryRequest,
  ) {
    return await this.request<
      TransformationDataModels,
      CreateDataModelFromQueryRequest
    >(
      dataTransformationUrlMap['createDataModelFromQuery'](teamId),
      'post',
      payload,
    );
  }

  // COLLECTIONS
  async getCollections({ teamId }: { teamId: string }) {
    return (
      (await this.get<Collection[]>(
        dataTransformationUrlMap['collections'](teamId),
      )) ?? []
    );
  }

  async createCollection(
    teamId: string,
    payload: CreateCollectionPayload,
  ): Promise<Collection | null> {
    return await this.request<Collection, CreateCollectionPayload>(
      dataTransformationUrlMap['collections'](teamId),
      'post',
      payload,
    );
  }

  async editCollection(
    teamId: string,
    collectionId: string,
    payload: UpdateCollectionPayload,
  ): Promise<Collection | null> {
    return await this.request<Collection, UpdateCollectionPayload>(
      dataTransformationUrlMap['getCollectionById'](teamId, collectionId),
      'put',
      payload,
    );
  }

  async deleteCollection(teamId: string, collectionId: string) {
    await this.request(
      dataTransformationUrlMap['getCollectionById'](teamId, collectionId),
      'delete',
    );
  }

  async batchDeleteCollection(
    teamId: string,
    payload: BatchDeleteCollectionsPayload,
  ) {
    await this.request<void, BatchDeleteCollectionsPayload>(
      dataTransformationUrlMap['batchDeleteCollection'](teamId),
      'delete',
      payload,
    );
  }

  async getCollectionById(teamId: string, collectionId: string) {
    const collectionDetail = await this.get<TransformationCollection>(
      dataTransformationUrlMap['getCollectionById'](teamId, collectionId),
    );
    return serializeData(collectionDetail, zTransformationCollection);
  }

  async editDataModel(
    teamId: string,
    datasetId: string,
    dataModelId: string,
    payload: UpdateCollectionPayload,
  ) {
    return await this.request<
      TransformationDataModels,
      UpdateCollectionPayload
    >(
      dataModelsURL.editDataModel(teamId, datasetId, dataModelId),
      'put',
      payload,
    );
  }

  editDataModelQueryString(
    teamId: string,
    datasetId: string,
    dataModelId: string,
    payload: QueryExecutionRequest,
  ) {
    return this.request<TransformationDataModels, QueryExecutionRequest>(
      dataModelsURL.editDataModelQueryString(teamId, datasetId, dataModelId),
      'put',
      payload,
    );
  }

  getDagNodes(teamId: string) {
    return this.get<TransformationDagQuery>(
      dataTransformationUrlMap.getDagNodes(teamId),
    );
  }

  editDagNodesConfig(teamId: string, payload: Array<TransformationDagPayload>) {
    // NOTE: payload maybe contain more than required fields
    const safePayload = payload.map((item) => {
      if (item.category === 'DATASET') {
        return {
          id: item.id,
          dag_config: item.dag_config,
          category: item.category,
        };
      }

      if (item.category) {
        return {
          dataset_id: item.dataset_id,
          id: item.id,
          dag_config: item.dag_config,
          category: item.category,
        };
      }
    });
    return this.request(
      dataTransformationUrlMap.editDagNodesConfig(teamId),
      'put',
      safePayload,
    );
  }
}

/**
 * @deprecated
 */
export const DataTransformationClientApiAdapter = Object.freeze(
  new DataTransformationApiAdapterClass(clientHttp),
);
/**
 * @deprecated
 */
export const DataTransformationServerApiAdapter = Object.freeze(
  new DataTransformationApiAdapterClass(serverHttp),
);

export const DataTransformationApi = {
  onBrowser: () => new DataTransformationApiAdapterClass(clientHttp),
  onServer: () => new DataTransformationApiAdapterClass(serverHttp),
};
const zBatchDeleteCollectionsPayload = z.object({
  collection_ids: z.array(z.string()),
  data_models: z.array(
    z.object({
      id: z.string(),
      dataset_id: z.string(),
    }),
  ),
});

export type BatchDeleteCollectionsPayload = z.infer<
  typeof zBatchDeleteCollectionsPayload
>;

export const zCollectionMaintainer = z.object({
  id: z.string(),
  username: z.string(),
  avatar_url: z.string().nullish(),
});
export type CollectionMaintainer = z.infer<typeof zCollectionMaintainer>;

export const zCollectionDataModelType = z.enum(['TABLE', 'VIEW']);
export type CollectionDataModelType = z.infer<typeof zCollectionDataModelType>;

export const zTemplateColumnType = z.enum([
  'STRING',
  'BOOLEAN',
  'DATETIME',
  'INTEGER',
  'DECIMAL',
  'DATE',
  'ARRAY',
  'TIMESTAMP',
  'INT64',
  'FLOAT64',
  'BIGNUMERIC',
  'NUMERIC',
  'BOOL',
]);
export type TemplateColumnType = z.infer<typeof zTemplateColumnType>;

export const zTransformationDataModels = z.object({
  category: z.literal('DATA_MODEL'),
  id: z.string(),
  team_id: z.string(),
  parent_collection_id: z.string().nullish(),
  description: z.string().nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
  creator: zCollectionMaintainer,
  updated_by: zCollectionMaintainer.nullish(),
  name: z.string(),
  dataset_id: z.string(),
  //
  type: zCollectionDataModelType.nullish(),
  columns: z.array(zCollectionDataModelColumn).nullish(),
  is_healthy: z.boolean(),
  _is_temp: z.boolean().nullish(),
  user_entered_query: z.string().nullish(),
  parameters: z.array(zQueryParameter).nullish(),
});

export type TransformationDataModels = z.infer<
  typeof zTransformationDataModels
>;

export const zCollectionType = z.enum(['DEFAULT', 'CUSTOMIZED']);
export type CollectionType = z.infer<typeof zCollectionType>;

export const zTransformationCollection = z.object({
  category: z.literal('COLLECTION'),
  id: z.string(),
  team_id: z.string(),
  parent_collection_id: z.string().nullish(),
  description: z.string().nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
  creator: zCollectionMaintainer.nullish(),
  updated_by: zCollectionMaintainer.nullish(),
  name: z.string(),
  //
  type: zCollectionType,
});
export type TransformationCollection = z.infer<
  typeof zTransformationCollection
>;

export const zCollection = z.union([
  zTransformationCollection,
  zTransformationDataModels,
]);

export type Collection = z.infer<typeof zCollection>;
const zCreateCollectionPayload = z.object({
  id: z.string(), // NOTE: client must specify id for sake of ease optimistic operations
  name: z.string(),
  description: z.string().nullish(),
  parent_collection_id: z.string().uuid().nullish(),
});
export type CreateCollectionPayload = z.infer<typeof zCreateCollectionPayload>;

const zTemplateColumn = z.object({
  name: z.string(),
  description: z.string().nullish(),
  // type: zTemplateColumnType.nullish(),
  // index: z.number().nullish(),
  type: zTemplateColumnType,
});
export type TemplateColumn = z.infer<typeof zTemplateColumn>;

export const zTemplateType = z.enum([
  'ONE_DATA_SOURCE_ONE_CONNECTION_PER',
  'ONE_DATA_SOURCE_MANY_CONNECTIONS_PER',
  'MANY_DATA_SOURCES_ONE_CONNECTION_PER',
  'MANY_DATA_SOURCES_MANY_CONNECTIONS_PER',
]);
export type TemplateType = z.infer<typeof zTemplateType>;

export const zTemplateDataSource = z.object({
  id: z.string(),
  name: z.string(),
  icon_url: z.string().nullish(),
});
export type TemplateDataSource = z.infer<typeof zTemplateDataSource>;

const zTemplateData = z.object({
  id: z.string(),
  original_id: z.string(),
  name: z.string(),
  type: zTemplateType,
  is_active: z.boolean(),
  data_sources: z.array(zTemplateDataSource).nullish(),
  config: z
    .object({
      columns: z.array(zTemplateColumn),
    })
    .nullish(),
  description: z.string().nullish(),
  created_at: z.string().nullish(),
  updated_at: z.string().nullish(),
});

export type TemplateData = z.infer<typeof zTemplateData>;
interface IBaseDagPayload {
  id: string;
  dag_config: IDagConfig;
}
export type TransformationDagPayload =
  | (IBaseDagPayload & { category: 'DATASET' })
  | (IBaseDagPayload & { category: 'DATA_MODEL'; dataset_id: string });
export type TransformationDagQuery = {
  datasets: Array<IDataSourceNode>;
  nodes: Array<DagNodeItem>;
};

const zUpdateCollectionPayload = z.object({
  name: z.string().nullish(),
  description: z.string().nullish(),
  parent_collection_id: z.string().uuid().nullish(),
  columns: z.array(zCollectionDataModelColumn.partial()).nullish(),
});

export type UpdateCollectionPayload = z.infer<typeof zUpdateCollectionPayload>;
