import lodashEscapeRegExp from 'lodash/escapeRegExp';
import lodashIsNumber from 'lodash/isNumber';
import randomBytes from 'randombytes';
import { z, ZodString } from 'zod';

import { NULL_STRING } from '@/components/FilterFormController/constants';
import { CALLBACK_URL_QUERY_KEY, EMAIL_QUERY_KEY } from '@/constants';
import { FilterItemTextValue } from '@/models/view';

// const ALPHA_NUMERIC_LATIN_REGEX_STR = 'a-zA-Z0-9';

const KOREAN_REGEX_STR = '\u3131-\uD79D';

// const NOT_START_END_WHITE_SPACES_REGEX_STR = `^[^\\s]([${ALPHA_NUMERIC_LATIN_REGEX_STR}${KOREAN_REGEX_STR}\\s])*[^\\s]$`;
const NOT_START_END_WHITE_SPACES_REGEX_STR = `^[^\\s].*[^\\s]$`;

const DEFAULT_MAX_CHARS_DEPRECATED = 40;
const DEFAULT_MIN_CHARS_DEPRECATED = 0;

export const DEFAULT_MIN_CHARS = 1;
export const DEFAULT_MAX_CHARS = 256;

export function isKoreanSyllable(str: string) {
  const koreanRegex = new RegExp(`[${KOREAN_REGEX_STR}]`, 'gi');
  return koreanRegex.test(str);
}

type StringLengthCalculator = (codepoint: string) => number;

export const koreanStrLengthCalculator: StringLengthCalculator = (
  codepoint: string,
) => {
  return isKoreanSyllable(codepoint) ? codepoint.length * 2 : codepoint.length;
};

export const zCharacterLengthConstrain = (
  zString: ZodString,
  min = DEFAULT_MIN_CHARS,
  max = DEFAULT_MAX_CHARS,
  errMsg = 'string-exceed-256-chars',
) =>
  zString
    .refine((data) => strlen(data, koreanStrLengthCalculator) >= min, {
      message: errMsg,
    })
    .refine((data) => strlen(data, koreanStrLengthCalculator) <= max, {
      message: errMsg,
    });

//
export const zEnglishKoreanStringSchema = (
  zString: ZodString,
  min = DEFAULT_MIN_CHARS_DEPRECATED,
  max = DEFAULT_MAX_CHARS_DEPRECATED,
) =>
  zString
    .refine((data) => strlen(data, koreanStrLengthCalculator) >= min, {
      message: 'invalid-string-too-short',
    })
    .refine((data) => strlen(data, koreanStrLengthCalculator) <= max, {
      message: 'invalid-string-too-long',
    })
    .refine(
      (data) => {
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
        return format.test(data) === false;
      },
      {
        message: 'invalid-string-contains-special-chars',
      },
    )
    .refine(
      (data) => {
        const notStartEndWhitespaces = new RegExp(
          NOT_START_END_WHITE_SPACES_REGEX_STR,
          // 'gui',
        );
        return notStartEndWhitespaces.test(data);
      },
      {
        message: 'invalid-string-start-end-whitespaces',
      },
    );

export function strlen(
  fullText: string,
  calculator: StringLengthCalculator,
): number {
  // let offsetUtf16 = 0;
  let offset = 0;

  const codepoints = fullText;
  const codepointsLength = codepoints.length;

  for (let i = 0; i < codepointsLength; i++) {
    const codepoint = codepoints[i];
    const nextOffset = offset + calculator(codepoint);

    offset = nextOffset;
    // offsetUtf16 += codepoint.length;
  }

  return offset;
}

export function calculateTextLenWithKorean(str: string) {
  return strlen(str, koreanStrLengthCalculator);
}

export function findMaxIndex(
  text: string,
  charlen: (char: string) => number,
  maxChars: number,
): number {
  let total = 0;
  const codepoints = text;
  const codepointsLength = codepoints.length;

  for (let i = 0; i < codepointsLength; i++) {
    if (total - maxChars >= 0) return i;
    const codepoint = codepoints[i];
    const nextTotal = total + charlen(codepoint);
    if (nextTotal - maxChars > 0) return i;
    total = nextTotal;
  }
  return codepointsLength;
}

export const emailZodSchema = z.string().email();

export function isValidEmail(inputValue: string) {
  const { success } = emailZodSchema.safeParse(inputValue);
  return success;
}

export const phoneNumberZodSchema = z
  .string({ required_error: 'invalid-mobile-required' })
  .regex(/^$|(\+\d{1,3}[- ]?)?\d{7,15}$/, 'invalid-string-mobile');

export const isUuid = (str: string) => z.string().uuid().safeParse(str).success;

export const compareStringOrNumber = (
  str1: string | null,
  str2: string | null,
): number => {
  if (!str1) return -1;
  if (!str2) return 1;
  if (lodashIsNumber(str1) && lodashIsNumber(str2)) {
    const num1 = parseFloat(str1);
    const num2 = parseFloat(str2);
    // If both strings can be converted to numbers, compare them as numbers
    if (num1 < num2) {
      return -1;
    } else if (num1 > num2) {
      return 1;
    } else {
      return 0;
    }
  } else {
    // If either or both strings cannot be converted to numbers, compare them as strings
    return str1.localeCompare(str2);
  }
};
export function generateOpaqueToken(length: number) {
  return randomBytes(length).toString('hex').slice(0, length);
}

export function testFullTextSearch(text: string, kw: string) {
  if (!text || !kw) return false;

  const escapedRegExp = lodashEscapeRegExp(kw.trim());
  const words = escapedRegExp.split(' ');
  const regex = new RegExp(words.join('|'), 'i');
  return regex.test(text);
}
// GENERAL UTILS
// STACK OVERFLOW

export const isValidUrl = (urlString: string | undefined | null) => {
  const zUrlSchema = z.string().url();
  const urlCheckResult = zUrlSchema.safeParse(urlString);

  return urlCheckResult.success;
};

export function uppercaseWithType<T extends string>(string: T) {
  return string.toUpperCase() as Uppercase<T>;
}

export function lowercaseWithType<T extends string>(string: T) {
  return string.toLowerCase() as Lowercase<T>;
}

export function cssObjectToInlineString(object: React.CSSProperties) {
  var option = new Option();

  Object.assign(option.style, object);

  return option.style.cssText;
}

export function getSafeFilterTextOptions(
  payload: FilterItemTextValue | Array<FilterItemTextValue>,
) {
  if (Array.isArray(payload)) {
    return payload.map((v) => v || NULL_STRING);
  }
  return payload;
}

export function getFilterOptionsValue(payload: Array<string>) {
  return payload.map((v) => (v === NULL_STRING ? null : v));
}

export const objectToArray = <T extends {}, K extends keyof T>(
  obj: T,
): Array<K> => Object.keys(obj) as Array<K>;

export function getAuthUrlWithParams(
  relative: string,
  email: string,
  callbackUrl: string,
) {
  const queryParams = new URLSearchParams();
  if (email) {
    queryParams.set(EMAIL_QUERY_KEY, email);
  }

  if (callbackUrl) {
    queryParams.set(CALLBACK_URL_QUERY_KEY, callbackUrl);
  }

  const params = queryParams.toString();

  if (params === '') return relative;

  return relative + '?' + queryParams.toString();
}
