import type { AxiosInstance, CreateAxiosDefaults } from 'axios';

import axios, { isAxiosError } from 'axios';

const TIMEOUT_MS = 1000 * 60;

const CONFIG: CreateAxiosDefaults = {
  baseURL: process.env.API,
  timeout: TIMEOUT_MS,
  headers: { accept: 'application/json' },
};

// NOTE: Create axios instance.
const axiosInstance = axios.create(CONFIG);

function registerApiErrorParserResponseInterceptor(instance: AxiosInstance) {
  const id = instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (isAxiosError(error)) {
        return Promise.reject(error.response?.data);
      }

      throw error;
    },
  );

  return () => {
    instance.interceptors.response.eject(id);
  };
}

registerApiErrorParserResponseInterceptor(axiosInstance);

export default axiosInstance;
