import { z } from 'zod';

import { BOARD_DEFAULT_NAME } from '@/feature-report/charts/board/constant';
import { makeSafeSchema } from '@/utils/data';

import {
  zAggregateItem,
  zGrouping,
  zGroupItem,
  zTimeSeriesOption,
} from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zBoardConfig = z.object({
  view_type: z.literal('BOARD'),
  title: makeSafeSchema(z.string(), BOARD_DEFAULT_NAME),
  time_series: makeSafeSchema(zTimeSeriesOption, {
    periodicity: 'DAILY',
  }),
});

export const zBoardFullConfig = zCompatibleCommonConfig.merge(zBoardConfig);

export type BoardFullConfig = z.infer<typeof zBoardFullConfig>;

export const zBoardRenderingConfig = zBoardFullConfig.merge(
  z.object({
    grouping: zGrouping.merge(
      z.object({
        sub_aggregates: z.array(zAggregateItem).length(1, {
          message: 'board-config-grouping-sub_aggregates-length-must-be-1',
        }),
      }),
    ),
  }),
);

export type BoardConfig = z.infer<typeof zBoardConfig>;

export const zBoardTransformedConfig = zBoardFullConfig.merge(
  z.object({
    grouping: z.object({
      columns: z
        .array(zGroupItem)
        .default([])
        .transform((val) => []),
      sub_aggregates: z
        .array(zAggregateItem)
        .default([])
        .transform((val) => val.slice(0, 1)),
    }),
    title: z.string().default(BOARD_DEFAULT_NAME),
  }),
);
