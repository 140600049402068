import { z } from 'zod';

import { makeSafeSchema } from '@/utils/data';

import { zAggregateItem, zGrouping, zGroupItem, zSortItem } from './common';
import { zCompatibleCommonConfig } from './compatibility';
import { zColumnSize } from './table';

export const zPivotConfig = z.object({
  // AI config
  view_type: z.literal('PIVOT_TABLE'),
  column_grouping: makeSafeSchema(z.array(zGroupItem), []).nullish(),
  row_grouping: makeSafeSchema(z.array(zGroupItem), []).nullish(),
  aggregate_value: makeSafeSchema(z.array(zAggregateItem), []).nullish(),
  sort: z.array(zSortItem).nullish(),
  // AI config
  column_size: zColumnSize.nullish(),
  row_sort: zSortItem.nullish(),
  grand_total_sort: zSortItem.nullish(),
  // pivot flat
  pivot_mode: z.enum(['GROUPED', 'FLAT']).nullish(),
  repeat_labels: z.boolean().nullish(),
  show_totals: z.boolean().nullish(),
});

// Prevent time series mode on in pivot
const zPivotCompatibilityGuardConfig = z.object({
  grouping: makeSafeSchema(
    zGrouping.merge(
      z.object({
        columns: z
          .array(zGroupItem)
          .refine((cols) => cols[0] && cols[0].column_type !== 'DATETIME')
          .catch((ctx) => {
            return [];
          }),
      }),
    ),
    {
      columns: [],
      sub_aggregates: [],
    },
  ),
});

export const zPivotFullConfig = zCompatibleCommonConfig
  .merge(zPivotCompatibilityGuardConfig)
  .merge(zPivotConfig);

export const zPivotRenderingConfig = zPivotFullConfig
  .merge(
    z.object({
      aggregate_value: z.array(zAggregateItem).min(1, {
        message: 'pivot-config-aggregate-value-min-length-is-1',
      }),
    }),
  )
  .refine(
    (config) => {
      // NOTE: sort can only contain column from column_grouping or row_grouping
      const sortConfig = config.sort;
      const invalidSort = sortConfig?.filter((item) => {
        if (
          !Boolean(
            config.column_grouping?.find(
              (col) => col.column_name === item.column_name,
            ),
          ) &&
          !Boolean(
            config.row_grouping?.find(
              (col) => col.column_name === item.column_name,
            ),
          )
        )
          return true;
        return false;
      });
      if (invalidSort && invalidSort?.length > 0) return false;
      return true;
    },
    {
      message:
        'pivot-config-sort-can-only-contain-column-from-column_grouping-and-row_grouping',
    },
  );

export type PivotFullConfig = z.infer<typeof zPivotFullConfig>;

export type PivotConfig = z.infer<typeof zPivotConfig>;
