export const MIN_WIDGET_HEIGHT_PX = 160;
export const MIN_WIDGET_HEADER_HEIGHT_PX = 36;
export const MIN_WIDGET_WIDTH_GRID_UNIT = 3;
export const MAX_WIDTH_GRID_UNIT = 12;
export const SUBSCRIPTION_WIDGET_HEIGHT_PX = 360;
export const SHARE_SETTING_DIALOG_WIDTH = 520;

export const MAX_ITEMS_IN_ROW = Math.floor(
  MAX_WIDTH_GRID_UNIT / MIN_WIDGET_WIDTH_GRID_UNIT,
);

export const TYPOGRAPHY_DICTIONARY = {
  1: 'h4',
  2: 'h5',
  3: 'small1',
} as const;

export const WIDGET_MIN_HEIGHT_DICT = {
  HEADER: MIN_WIDGET_HEADER_HEIGHT_PX,
  TEXT: MIN_WIDGET_HEIGHT_PX,
  REPORT: MIN_WIDGET_HEIGHT_PX,
};

export const WIDGET_DRAG_HANDLE_CLASS_NAME = 'Cg__drag-handle';
export const WIDGET_ACTIONS_CLASS_NAME = 'Cg__menu-action';

export const WIDGET_HEADER_HEIGHT_PX = '26px';

export const DELETE_WIDGET_WARNING_TEXT = {
  REPORT: 'page-dashboard.confirm-delete-report',
  TEXT: 'page-dashboard.confirm-delete-note',
  HEADER: 'page-dashboard.confirm-delete-header',
} as const;

export const SOURCE_IMAGE_WIDTH = 20;

export const RESPONSIVE_GRID_BREAKPOINT = {
  px: {
    xs: '32px',
    xl: '52px',
    '2xl': '72px',
    '3xl': '92px',
    '4xl': '112px',
    '5xl': '132px',
  },
  maxWidth: {
    xs: 900,
    xl: 1280,
    '2xl': 1360,
    '3xl': 1520,
    '4xl': 1800,
    '5xl': 2220,
  },
};

export const DASHBOARD_VIEW_PARAM = 'view';
export const DASHBOARD_RIGHT_PANEL_PARAM = 'right-panel';
export const DASHBOARDS_FULL_WIDTH_LS_KEY = 'dashboards-full-width';
