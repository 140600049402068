const API_VERSION = '';

export const teamsURL = {
  teamsId: (id: string) => `${API_VERSION}/teams/${id}`,
  permissions: (teamId: string) => teamsURL.teamsId(teamId) + '/permissions',
};

export const connectionURL = {
  getAll: (teamId: string) => `${teamsURL.teamsId(teamId)}/connections`,
  getById: (teamId: string, connectionId: string) =>
    `${connectionURL.getAll(teamId)}/${connectionId}`,
  syncById: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/sync`,
  downloadSyncHistories: (
    teamId: string,
    connectionId: string,
    historyId: string,
  ) =>
    `${connectionURL.getById(
      teamId,
      connectionId,
    )}/sync-histories/${historyId}/download`,
  statistics: (teamId: string) => `${connectionURL.getAll(teamId)}/statistics`,
  position: (teamId: string) => `${connectionURL.getAll(teamId)}/positions`,
  schedules: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/schedules`,
  schedulesId: (teamId: string, connectionId: string, scheduleId: string) =>
    `${connectionURL.schedules(teamId, connectionId)}/${scheduleId}`,
  schemas: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/schemas`,
  columns: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/columns`,
  syncHistories: (teamId: string, connectionId: string) =>
    `${connectionURL.getById(teamId, connectionId)}/sync-histories`,
  downloadByUrl: (teamId: string, connectionId: string, historyId: string) =>
    `${connectionURL.getById(
      teamId,
      connectionId,
    )}/sync-histories/${historyId}/download-url`,
};

export const dataSourcesURL = {
  getAll: () => `${API_VERSION}/data-sources`,
  getById: (dsId: string) => `${dataSourcesURL.getAll()}/${dsId}`,
};

export const dataModelsURL = {
  getAll: (teamId: string) => `${teamsURL.teamsId(teamId)}/datasets`,

  getById: (teamId: string, datasetId: string) =>
    `${dataModelsURL.getAll(teamId)}/${datasetId}/data-models`,

  getByDataModelId: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getById(teamId, datasetId)}/${dataModelId}`,

  editDataModel: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getById(teamId, datasetId)}/${dataModelId}`,

  editDataModelQueryString: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) =>
    `${dataModelsURL.getByDataModelId(teamId, datasetId, dataModelId)}/query`,

  getDownstreams: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getByDataModelId(
      teamId,
      datasetId,
      dataModelId,
    )}/downstreams`,

  getRelatedItems: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getByDataModelId(
      teamId,
      datasetId,
      dataModelId,
    )}/related-items`,

  getDataModelQuery: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getByDataModelId(teamId, datasetId, dataModelId)}/query`,

  duplicateDataModels: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) =>
    `${dataModelsURL.getByDataModelId(
      teamId,
      datasetId,
      dataModelId,
    )}/duplicate`,

  getConfig: (teamId: string, datasetId: string, dataModelId: string) =>
    `${dataModelsURL.getByDataModelId(teamId, datasetId, dataModelId)}/config`,

  filterByDatasetId: (teamId: string) =>
    `${dataModelsURL.getAll(teamId)}/filter`,
};

export const dataTransformationUrlMap = {
  _base(teamId: string) {
    return `${API_VERSION}/teams/${teamId}`;
  },
  // Collections
  collections(teamId: string) {
    return `${this._base(teamId)}/collections`;
  },
  batchDeleteCollection(teamId: string) {
    return `${this.collections(teamId)}/bulk-delete`;
  },
  getCollectionById(teamId: string, collectionId: string) {
    return `${this.collections(teamId)}/${collectionId}`;
  },
  // Transformations
  transformations(teamId: string) {
    return `${this._base(teamId)}/transformations`;
  },
  templateConnections(teamId: string, templateId: string) {
    return `${this.transformations(
      teamId,
    )}/templates/${templateId}/available-connections`;
  },
  templatePreviewQuery(teamId: string, templateId: string) {
    return `${this.transformations(
      teamId,
    )}/templates/${templateId}/preview-query`;
  },
  executeQuery(teamId: string) {
    return `${this.transformations(teamId)}/queries`;
  },
  createDataModelFromQuery(teamId: string) {
    return `${this.transformations(teamId)}/data-models`;
  },
  getDagNodes(teamId: string) {
    return `${this.transformations(teamId)}/dag`;
  },
  editDagNodesConfig(teamId: string) {
    return `${this.getDagNodes(teamId)}/item-configs`;
  },
  // Templates
  templates: () => `${API_VERSION}/data-model-templates` as const,
};

export const dashboardUrl = {
  detail: (teamId: string, dashboardId: string) =>
    `${API_VERSION}/teams/${teamId}/dashboards/${dashboardId}`,
  publishing: (teamId: string, dashboardId: string) =>
    `${dashboardUrl.detail(teamId, dashboardId)}/publishing`,
  generateReadOnlyToken: (dashboardId: string) =>
    `${dashboardUrl.detail('{team_id}', dashboardId)}/generate-read-only-token`,
};

export const widgetUrl = {
  all: (teamId: string, dashboardId: string) =>
    `${dashboardUrl.detail(teamId, dashboardId)}/widgets`,
  detail: (teamId: string, dashboardId: string, widgetId: string) =>
    `${widgetUrl.all(teamId, dashboardId)}/${widgetId}`,
  duplicate: (teamId: string, dashboardId: string, widgetId: string) =>
    `${widgetUrl.detail(teamId, dashboardId, widgetId)}/duplicate`,
};

export const discussionUrl = {
  all: (teamId: string, dashboardId: string) =>
    `/teams/${teamId}/dashboards/${dashboardId}/discussions`,
  countAll: (teamId: string, dashboardId: string) =>
    `${discussionUrl.all(teamId, dashboardId)}/_count`,
  comments: (teamId: string, dashboardId: string, discussionId: string) =>
    `${discussionUrl.all(teamId, dashboardId)}/${discussionId}/comments`,
  countComments: (teamId: string, dashboardId: string, discussionId: string) =>
    `${discussionUrl.comments(teamId, dashboardId, discussionId)}/_count`,
};

export const templateUrl = {
  all: () => `/dashboard-templates`,
  metadata: () => `${templateUrl.all()}/metadata`,
  detail: (templateId: string, teamId?: string) => {
    const queryParams = teamId
      ? new URLSearchParams({ team_id: teamId }).toString()
      : '';

    return `${templateUrl.all()}/${templateId}?${queryParams}`;
  },
  request: () => `${templateUrl.all()}/request`,
};
