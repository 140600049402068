'use client';

import Image from 'next/image';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { signOut } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import AppButton from '@/components/Button';
import { AppRoutes } from '@/routes';

export const ExpiredSessionErrorPage = () => {
  const { t } = useTranslation('common', { keyPrefix: 'error-page' });

  return (
    <Stack width="100%" height="100%">
      <Container maxWidth="md" sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          gap="16px"
          //
        >
          <Image
            src="/assets/logo-minimal.png"
            height="30"
            width="30"
            quality={100}
            alt="Endash icon"
            priority
          />
          <Stack gap="8px">
            <Typography variant="h2" color="neutralV2.0" textAlign="center">
              {t('session-expired')}
            </Typography>
            <Typography variant="body2" color="neutralV2.2" textAlign="center">
              {t('please-login-again-to-continue')}
            </Typography>
          </Stack>
          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            //
          >
            <AppButton
              color="primary"
              semiBold
              onClick={() => {
                // Notes: Clean cookies and redirect to login page
                signOut({ callbackUrl: AppRoutes.SIGNIN });
              }}
              sx={{ padding: '7px 12px' }}
              //
            >
              <Typography variant="small1" color="neutralV2.8">
                {t('login')}
              </Typography>
            </AppButton>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
