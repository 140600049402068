import { FALLBACK_LNG } from '@app/_internal/i18n/settings';

import { DASHBOARD_RIGHT_PANEL_PARAM } from './feature-dashboard/constant';
import { MainTab } from './feature-dashboard/provider';

export enum AppRoutes {
  INDEX = '/d', // NOTE: main redirect page, please do NOT remove it
  TEAMS = '/d',
  REPORT = '/report',
  SIGNIN = '/auth/signin',
  SIGNUP = '/auth/signup',
  OTP = '/auth/otp',
  ERROR = '/auth/error',
  CREATE_TEAM = '/create-team',
  CREATE_PROFILE = '/create-profile',
  PUBLIC_DASHBOARD = '/d/site',
  SUBSCRIPTION_UNSUBSCRIBE = '/subscription/_unsubscribe_email',
  EXTERNAL_DATA_PROVIDER = '/e',
  ALERT_UNSUBSCRIBE = '/alert/_unsubscribe_email',
  TEMPLATE = '/d/template',
}

export enum ROUTE {
  TRANSFORMATION = 'transformation',
  CONNECTION = 'connect',
  DATASET = 'dataset',
  DATA_MODEL = 'data-model',
  SUBSCRIPTION = 'subscription',
  ALERT = 'alert',
  TEMPLATE = 'template',
}

export function getLocale() {
  // IMPORTANT: for server side, we need to return empty string, else it will be duplicated locales /ko/en
  if (typeof window === 'undefined') return '';

  // NOTE: Assume the locale is already prefixed by middleware
  return window.location.pathname.split('/')[1] || FALLBACK_LNG;
}

function normalizePathname(pathname: string) {
  // Remove duplicate slashes
  return pathname.replaceAll(/\/+/g, '/');
}

export function getTeamRoute(teamId: string, locale?: string) {
  return normalizePathname(
    '/' + [locale || getLocale(), AppRoutes.TEAMS, teamId].join('/'),
  );
}

export function getDashboardRoute({
  teamId,
  dashboardId,
  locale,
  options,
}: {
  teamId: string;
  dashboardId: string;
  locale?: string;
  options?: { rightPanel?: MainTab; scrollToWidgetId?: string };
}) {
  const queryParams = new URLSearchParams();

  if (options?.rightPanel) {
    queryParams.set(DASHBOARD_RIGHT_PANEL_PARAM, options.rightPanel);
  }

  if (options?.scrollToWidgetId) {
    queryParams.set('hash', options.scrollToWidgetId);
  }

  const path = [getTeamRoute(teamId, locale), dashboardId].join('/');
  const search = queryParams.toString();

  return `${path}${search ? `?${search}` : ''}`;
}

export function getTemplateRoute(
  teamId: string,
  templateId: string,
  locale?: string,
) {
  return [getTeamRoute(teamId, locale), ROUTE.TEMPLATE, templateId].join('/');
}

export function getPublicTemplateRoute(templateId: string, locale?: string) {
  return ['', locale || getLocale(), 'd', ROUTE.TEMPLATE, templateId].join('/');
}

export function getPublicTemplatePreviewRoute(
  templateId: string,
  previewDashboardId: string,
  locale?: string,
) {
  return [
    '',
    locale || getLocale(),
    'd',
    ROUTE.TEMPLATE,
    templateId,
    'preview',
    previewDashboardId,
  ].join('/');
}

export function getTransformationRoute(teamId: string, locale?: string) {
  return [getTeamRoute(teamId, locale), ROUTE.TRANSFORMATION].join('/');
}

export function getSubscriptionRoute(teamId: string, locale?: string) {
  return [getTeamRoute(teamId, locale), ROUTE.SUBSCRIPTION].join('/');
}

export function getAlertRoute(teamId: string, locale?: string) {
  return [getTeamRoute(teamId, locale), ROUTE.ALERT].join('/');
}

export enum TransformationDataModelPanel {
  PREVIEW = 'preview',
  BUILD = 'build',
  COLUMNS = 'columns',
  RELATED = 'related',
  DETAILS = 'details',
  MANAGE_EXPORT = 'manage-export',
}

export function getTransformationGraphRoute(teamId: string) {
  return [getTransformationRoute(teamId), 'graph'].join('/');
}

export function getTransformationDataModelPanel(
  teamId: string,
  datasetId: string,
  dataModelId: string,
  panel: TransformationDataModelPanel = TransformationDataModelPanel.PREVIEW,
) {
  return [
    getTransformationCollectionRoute(teamId, datasetId),
    ROUTE.DATA_MODEL,
    dataModelId,
    panel,
  ].join('/');
}

export function getTransformationDatasetDetailRoute(
  teamId: string,
  datasetId: string,
  locale?: string,
) {
  return [
    getTransformationRoute(teamId, locale),
    ROUTE.DATASET,
    datasetId,
  ].join('/');
}

export function getTransformationDataModelDetailRoute(
  teamId: string,
  datasetId: string,
  dataModelId: string,
  panel: TransformationDataModelPanel = TransformationDataModelPanel.COLUMNS,
) {
  return [
    getTransformationDatasetDetailRoute(teamId, datasetId),
    dataModelId,
    panel,
  ].join('/');
}

export function getWidgetRoute(
  teamId: string,
  dashboardId: string,
  widgetId: string,
  isShowSettings: boolean | 'sort' = false,
) {
  const url = [getDashboardRoute({ teamId, dashboardId }), widgetId].join('/');
  const params = new URLSearchParams({
    show_settings: isShowSettings.toString(),
  });
  return `${url}?${params.toString()}`;
}

export function getViewRoute(
  teamId: string,
  dashboardId: string,
  widgetId: string,
  viewId: string,
  isShowSettings: boolean | 'sort' = false,
) {
  const url = getWidgetRoute(teamId, dashboardId, widgetId);
  const params = new URLSearchParams({
    show_settings: isShowSettings.toString(),
    default_view_id: viewId,
  });
  return `${url}?${params.toString()}`;
}

export function getHashDashboardRoute(
  teamId: string,
  dashboardId: string,
  widgetId: string,
) {
  return getDashboardRoute({
    teamId,
    dashboardId,
    options: { scrollToWidgetId: widgetId },
  });
}

export function getTeamInvitationRoute(teamId: string) {
  return [AppRoutes.TEAMS, teamId, 'invitations'].join('/');
}

export function getConnectionRoute(
  teamId: string,
  connectionId?: string,
  locale?: string,
) {
  if (!connectionId) {
    return [getTeamRoute(teamId, locale), ROUTE.CONNECTION].join('/');
  }
  return [getTeamRoute(teamId, locale), ROUTE.CONNECTION, connectionId].join(
    '/',
  );
}

export function getCSVConnectionRoute(teamId: string, connectionId: string) {
  return [getTeamRoute(teamId), ROUTE.CONNECTION, connectionId, 'csv'].join(
    '/',
  );
}

export function getConnectionStatusRoute(
  teamId: string,
  connectionId: string,
  locale?: string,
) {
  return [getTeamRoute(teamId, locale), ROUTE.CONNECTION, connectionId].join(
    '/',
  );
}

export function getConnectionSchemaRoute(
  teamId: string,
  connectionId: string,
  locale?: string,
) {
  return [
    getTeamRoute(teamId, locale),
    ROUTE.CONNECTION,
    connectionId,
    'schema',
  ].join('/');
}

export function getConnectionRouteSelect(teamId: string, locale?: string) {
  if (!teamId) return AppRoutes.INDEX;
  return [getTeamRoute(teamId, locale), ROUTE.CONNECTION, 'select'].join('/');
}

export function getConnectionSetupRouteWithReAuth(
  teamId: string,
  connectionId: string,
) {
  return [
    getTeamRoute(teamId),
    ROUTE.CONNECTION,
    connectionId,
    'setup?re-auth=true',
  ].join('/');
}

export function getConnectionSetupRoute(
  teamId: string,
  connectionId: string,
  locale?: string,
) {
  return [
    getTeamRoute(teamId, locale),
    ROUTE.CONNECTION,
    connectionId,
    'setup',
  ].join('/');
}

export function getTransformationCollectionRoute(
  teamId: string,
  collectionId: string,
  locale?: string,
) {
  return getTransformationDetailRoute(teamId, collectionId, locale);
}

export function getTransformationDetailRoute(
  teamId: string,
  collectionId: string,
  locale?: string,
) {
  return [
    getTeamRoute(teamId, locale),
    ROUTE.TRANSFORMATION,
    collectionId,
  ].join('/');
}

export function getPublicDashboardRoute(
  dashboardName: string,
  dashboardId: string,
) {
  const params = [
    encodeURIComponent(dashboardName.substring(0, 20)),
    dashboardId,
  ];

  const name = params.join('-');

  return [AppRoutes.PUBLIC_DASHBOARD, name].join('/');
}

export function getRecentRoute(teamId: string, locale: string) {
  return [getTeamRoute(teamId, locale), 'recent'].join('/');
}

export function getAccountSettingRoute(teamId: string, locale: string) {
  return [getTeamRoute(teamId, locale), 'profile'].join('/');
}

export function getTeamSettingRoute(teamId: string, locale: string) {
  return [getTeamRoute(teamId, locale), 'setting'].join('/');
}

export function getBillingRoute(teamId: string, locale: string) {
  return [getTeamRoute(teamId, locale), 'billing'].join('/');
}

export function getDashboardGuideRoute(
  teamId: string,
  dashboardId: string,
  locale?: string,
) {
  return [getTeamRoute(teamId, locale), dashboardId, 'guide'].join('/');
}

/**
 * PATH GENERATOR
 */
type PathParams<T extends string> = T extends `:${infer Param}` ? Param : never;

type SearchParams<T extends string> = T extends `?${infer Param}`
  ? Param
  : never;

type ExtractParams<T extends string> = PathParams<T> | SearchParams<T>;

type RouteParams<T extends string[]> = {
  [K in ExtractParams<T[number]>]: string;
};

type HasRequiredParams<T extends string[]> = ExtractParams<
  T[number]
> extends never
  ? false
  : true;

type BuildFn<T extends string[]> = (params: RouteParams<T>) => string;

type AppPathname<T extends string[]> = {
  template: string;
  build: BuildFn<T>;
  extend: <U extends string[]>(...segments: U) => AppPathname<[...T, ...U]>;
  match: (path: string) => boolean;
};

function createAppPathname<T extends string[]>(...segments: T): AppPathname<T> {
  const joinedPath = segments.join('/');
  const searchSegments = segments.filter((segment) => segment.startsWith('?'));
  const pathTemplate = joinedPath.replace(/\/\?[^/]+/g, '').replace(/\/$/, '');
  return {
    template: `/${pathTemplate}`,
    build: (routeParams?: RouteParams<T>) => {
      let pathname = pathTemplate;
      const searchParams = new URLSearchParams();
      if (routeParams) {
        Object.entries(routeParams).forEach(([key, value]) => {
          if (typeof value === 'string' && value) {
            if (searchSegments.includes(`?${key}`)) {
              searchParams.append(key, value);
            } else {
              pathname = pathname.replace(`:${key}`, value);
            }
          }
        });
      }
      const search = searchParams.toString();
      return `/${pathname}${search ? `?${search}` : ''}`;
    },
    extend: <U extends string[]>(...additionalSegments: U) => {
      return createAppPathname(...segments, ...additionalSegments);
    },
    match: (path: string) => {
      const [inputPath, queryString] = path.split('?');
      const searchParams = new URLSearchParams(queryString);
      const pattern = pathTemplate
        .replace(/:[^/]+/g, '[^/]+')
        .replace(/\//g, '\\/');
      const regex = new RegExp(`^\\/${pattern}$`);
      if (!regex.test(inputPath)) return false;
      if (searchSegments.length === 0) return true;
      return searchSegments.every((segment) => {
        const param = segment.slice(1);
        return searchParams.has(param);
      });
    },
  };
}

const indexPathname = createAppPathname(':lang', 'd');

const teamPathname = indexPathname.extend(':team_id');

const dashboardPathname = teamPathname.extend(':dashboard_id');

const widgetPathname = dashboardPathname.extend(':widget_id');

const templatePathname = teamPathname;

const templateDetailPathname = templatePathname.extend(
  'template',
  ':template_id',
);

const templatePreviewPathname = templatePathname.extend(
  'template',
  ':template_id',
  'preview',
  ':preview_dashboard_id',
);

export { templateDetailPathname, templatePathname, templatePreviewPathname };
