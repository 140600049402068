import { z } from 'zod';

import { makeSafeSchema } from '@/utils/data';

import {
  zAggregateItem,
  zGrouping,
  zGroupItem,
  zTimeSeriesOption,
} from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zLineChartConfig = z.object({
  view_type: z.literal('LINE_CHART'),
  time_series: makeSafeSchema(zTimeSeriesOption, {
    periodicity: 'DAILY',
  }),
});

export const zLineChartFullConfig =
  zCompatibleCommonConfig.merge(zLineChartConfig);

export type LineChartFullConfig = z.infer<typeof zLineChartFullConfig>;

export const zLineChartRenderingConfig = zLineChartFullConfig.merge(
  z.object({
    grouping: zGrouping.merge(
      z.object({
        columns: z
          .array(zGroupItem)
          .min(1, {
            message: 'line-chart-config-grouping-columns-length-min-1',
          })
          .max(2, {
            message: 'line-chart-config-grouping-columns-length-max-2',
          })
          .refine((cols) => cols[0] && cols[0].column_type === 'DATETIME', {
            message: 'line-chart-fisrt-grouping-column-not-datetime',
          }),
        sub_aggregates: z.array(zAggregateItem).length(1, {
          message: 'line-chart-config-grouping-sub_aggregates-length-eq-1',
        }),
      }),
    ),
    // time_series: zTimeSeriesOption,
  }),
);
export type LineChartConfig = z.infer<typeof zLineChartConfig>;
