import type { DashboardHealthCheckInput } from './adapters/api/dashboard';
import type { DatasetPayload, ReportRequestBody } from './feature-report/types';
import type { GetTeamMembersPayload } from './layouts/api';
import type { DataModelType, DatasetType } from './models/data-model';
import type { ColumnAggregate, FilterItem } from './models/view';

import { Query } from '@tanstack/react-query';
import lodashCloneDeep from 'lodash/cloneDeep';
import objectHash from 'object-hash';

import { DashboardTemplateParams } from './adapters/api';
import { getReportQKFromBody } from './feature-report/services';
import { ExportFilterParams } from './models/export';

// === QUERY KEY FACTORIES

export const userQK = {
  profile: (userId: string) => ['me', userId] as const,
  teams: (userId: string) => [...userQK.profile(userId), 'teams'] as const,
  permissions: (userId: string) =>
    [...userQK.profile(userId), 'permissions'] as const,
} as const;

export const dataModelQK = {
  main: 'data-model',
  all: (teamId: string, dataSetType: DatasetType | 'ALL' = 'ALL') => [
    dataModelQK.main,
    'of-team-or-data-set',
    teamId,
    'of-type',
    dataSetType,
  ],
  detail: (teamId: string, dataModelId: string) => [
    ...dataModelQK.all(teamId),
    dataModelId,
  ],
  listModel: (teamId: string, listDataset: Array<DatasetPayload>) => [
    dashboardQK.main,
    teamId,
    listDataset,
  ],
  getByDataset: (
    teamId: string,
    datasetId: string,
    dataModelType: DataModelType = 'TABLE',
  ) => [...dataModelQK.all(teamId), datasetId, dataModelType],
  getByIdAndDatasetId: (
    teamId: string,
    dataModelId: string,
    datasetId: string,
  ) => [...dataModelQK.all(teamId), dataModelId, datasetId],
  getBySearchValue: (
    teamIdOrDatasetId: string,
    dataModelId: string,
    columnName: string,
    searchValue = '',
  ) =>
    searchValue != null
      ? ([
          ...dataModelQK.all(teamIdOrDatasetId),
          dataModelId,
          'on-column',
          columnName,
          'filter-by-search-value',
          searchValue,
        ] as const)
      : ([...dataModelQK.detail(teamIdOrDatasetId, dataModelId)] as const),
  getSqlQuery: ({
    teamId,
    datasetId,
    dataModelId,
  }: {
    teamId: string;
    datasetId: string;
    dataModelId: string;
  }) =>
    [
      ...dataModelQK.getByDataset(teamId, datasetId),
      'dataModelId=' + dataModelId,
      'sql-query',
    ] as const,
  getDataModelsLogs: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) => [...dataModelQK.detail(teamId, dataModelId), datasetId, 'logs'],
  getRelatedItems: ({
    teamId,
    datasetId,
    dataModelId,
  }: {
    teamId: string;
    datasetId: string;
    dataModelId: string;
  }) => [
    ...dataModelQK.detail(teamId, dataModelId),
    datasetId,
    'related-items',
  ],
  getDataModelsDetail: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) => [...dataModelQK.detail(teamId, dataModelId), datasetId],
  getDownstreams: ({
    teamId,
    datasetId,
    dataModelId,
  }: {
    teamId: string;
    datasetId: string;
    dataModelId: string;
  }) =>
    [
      ...dataModelQK.detail(teamId, dataModelId),
      datasetId,
      'downstreams-models',
    ] as const,
  getDataModelConfig: (
    teamId: string,
    datasetId: string,
    dataModelId: string,
  ) =>
    [
      ...dataModelQK.getDataModelsDetail(teamId, datasetId, dataModelId),
      'config',
    ] as const,
} as const;

export const teamQK = {
  _main: 'team',
  detail: (teamId: string) => [teamQK._main, teamId],
  members: (params: GetTeamMembersPayload) => {
    return [
      ...teamQK.detail(params.team_id),
      params.team_id,
      'members',
      params.size.toString() ?? '',
      params.offset.toString() ?? '',
      params.search_keyword ?? '',
    ];
  },
  guests: (params: GetTeamMembersPayload) => {
    return [
      ...teamQK.detail(params.team_id),
      params.team_id,
      'guests',
      params.size.toString() ?? '',
      params.offset.toString() ?? '',
      params.search_keyword ?? '',
    ];
  },
  status: (teamId: string) => [...teamQK.detail(teamId), 'invitations'],
  billing: (teamId: string, month: number, year: number) => [
    ...teamQK.detail(teamId),
    'billing-usage',
    month,
    year,
  ],
} as const;

export const UPSTREAM_CONNECTIONS_KEY = 'upstream-connections';

export const dashboardQK = {
  main: 'dashboards',
  all: (teamId: string) => [
    dashboardQK.main,
    'of-team',
    teamId,
    dashboardQK.main,
  ],
  detail: (teamId: string, dashboardId: string) => [
    ...dashboardQK.all(teamId),
    dashboardId,
  ],
  upstreamConnections: (teamId: string, dashboardId: string) => [
    ...dashboardQK.detail(teamId, dashboardId),
    'upstream-connections',
  ],
  availableDataSources: (teamId: string, dashboardId: string) => [
    ...dashboardQK.detail(teamId, dashboardId),
    'available-data-sources',
  ],
  templates: (teamId: string) => [...dashboardQK.all(teamId), 'templates'],
  shareSettings: (teamId: string, dashboardId: string) => [
    ...dashboardQK.detail(teamId, dashboardId),
    'share-settings',
  ],
  filtersHealthCheck: ({
    teamId,
    dashboardId,
    filters,
  }: {
    teamId: string;
    dashboardId: string;
    filters: DashboardHealthCheckInput;
  }) =>
    [
      ...dashboardQK.detail(teamId, dashboardId),
      'filters',
      'health-check',
      ['inputs', objectHash(filters, { unorderedArrays: true })],
      // // NOTE: only care about columns
      // objectHash(
      //   filters.reduce<
      //     Array<{
      //       name: string;
      //       type: string;
      //       dataModelId: string;
      //       datasetId: string | null;
      //     }>
      //   >((acc, curr) => {
      //     if (curr?.filter?.column_filters?.length) {
      //       const normalizedColumns = curr.filter.column_filters.map<{
      //         name: string;
      //         type: string;
      //         dataModelId: string;
      //         datasetId: string | null;
      //       }>((col) => ({
      //         name: col.column_filter.column_name,
      //         type: col.column_filter.column_type,
      //         dataModelId: col.data_model_id,
      //         datasetId: col.dataset_id,
      //       }));
      //       acc.push(...normalizedColumns);
      //     }
      //     return acc;
      //   }, []),
      //   {
      //     unorderedArrays: true,
      //     unorderedObjects: true,
      //   },
      // ),
    ] as const,
} as const;

export const connectionQK = {
  main: 'connections',
  all: (teamId: string) => [connectionQK.main, ...teamQK.detail(teamId)],
  schemas: (teamId: string, connectionId: string) => [
    connectionQK.main,
    ...teamQK.detail(teamId),
    connectionId,
    'schemas',
  ],
  columns: (
    teamId: string,
    connectionId: string,
    schemaName: string,
    tableName: string,
  ) => [
    connectionQK.main,
    ...teamQK.detail(teamId),
    connectionId,
    'schemas',
    schemaName,
    'columns',
    tableName,
  ],
  schedules: (teamId: string, connectionId: string) => [
    connectionQK.main,
    ...teamQK.detail(teamId),
    connectionId,
    'schedules',
  ],
  detail: (teamId: string, connectionId: string) => [
    ...connectionQK.all(teamId),
    'connectionId',
    connectionId,
  ],
  statistic: (teamId: string) => [...connectionQK.all(teamId), 'statistic'],
  syncHistories: (teamId: string, connectionId: string) => [
    ...connectionQK.detail(teamId, connectionId),
    'sync-histories',
  ],
  allCSV: (teamId: string) => [
    connectionQK.main,
    ...teamQK.detail(teamId),
    'csv',
  ],
  connectionPosition: (teamId: string) => [
    connectionQK.main,
    ...teamQK.detail(teamId),
    'connections-position',
  ],
} as const;

export const widgetQK = {
  main: 'widgets',
  all: (teamId: string, dashboardId: string) =>
    [widgetQK.main, 'of-team', teamId, 'of-dashboard', dashboardId] as const,
  detail: (teamId: string, dashboardId: string, widgetId: string) =>
    [...widgetQK.all(teamId, dashboardId), 'with-id', widgetId] as const,
} as const;

export const viewQK = {
  main: 'views',
  detail: (
    teamId: string,
    dashboardId: string,
    widgetId: string,
    viewId: string,
  ) => [...widgetQK.detail(teamId, dashboardId, widgetId), viewQK.main, viewId],
} as const;

export const REPORT_ROOT_KEY = 'reports';

export const reportQK = {
  all: REPORT_ROOT_KEY,

  dashboard: (dashboardId: string) => [reportQK.all, dashboardId],
  widget: (dashboardId: string, widgetId: string) => [
    ...reportQK.dashboard(dashboardId),
    widgetId,
  ],
  prefix: ({
    teamId,
    dataModelId,
    datasetId,
    dashboardId,
    widgetId,
    viewId,
  }: {
    teamId?: string;
    dataModelId?: string;
    datasetId?: string;
    dashboardId?: string;
    widgetId?: string;
    viewId?: string | null;
  }) => {
    const specifier: Record<string, string> = {};
    if (teamId) specifier.teamId = teamId;
    if (dataModelId) specifier.dataModelId = dataModelId;
    if (datasetId) specifier.datasetId = datasetId;
    if (dashboardId) specifier.dashboardId = dashboardId;
    if (widgetId) specifier.widgetId = widgetId;
    if (viewId) specifier.viewId = viewId;

    return [REPORT_ROOT_KEY, specifier];
  },
  request: ({
    teamId,
    dataModelId,
    datasetId,
    dashboardId,
    widgetId,
    viewId,
    reqBody,
  }: {
    teamId?: string;
    dataModelId?: string;
    datasetId?: string;
    dashboardId?: string;
    widgetId?: string;
    viewId?: string;
    reqBody: ReportRequestBody | null;
  }) => {
    const reqBodyKey = getReportQKFromBody(reqBody);
    return [
      ...reportQK.prefix({
        teamId,
        dataModelId,
        datasetId,
        dashboardId,
        widgetId,
        viewId,
      }),
      reqBodyKey,
    ] as const;
  },
  view: (
    dashboardId: string,
    widgetId: string,
    viewId: string,
    reportRequestBodyJSON: string,
    grouping: string,
  ) => {
    const copiedBody = JSON.parse(reportRequestBodyJSON);
    const sourceName = copiedBody?.data?.source?.name ?? '#';

    return [
      ...reportQK.prefix({ dashboardId, widgetId, viewId }),
      reportRequestBodyJSON,
      sourceName,
      grouping,
    ] as const;
  },
  viewDetail: (
    dashboardId: string,
    widgetId: string,
    viewId: string,
    dataModelId: string,
    reportRequestBody: ReportRequestBody | null,
    grouping: string[],
  ) => {
    if (!reportRequestBody)
      return [
        ...reportQK.widget(dashboardId, widgetId),
        ['view', viewId],
        ['request_body', 'null'],
        ['columns'],
        dataModelId,
        grouping,
      ] as const;

    const clonedRequestBody = lodashCloneDeep(reportRequestBody);

    // NOTE: hash un-ordered data
    const sourceColumnHash = objectHash(clonedRequestBody.data.source.columns, {
      unorderedArrays: true,
    });

    // NOTE: remove after hash
    clonedRequestBody.data.source.columns = [];

    const requestBodyHash = objectHash(clonedRequestBody);

    return [
      ...reportQK.widget(dashboardId, widgetId),
      ['view', viewId],
      ['request_body', requestBodyHash],
      ['columns', sourceColumnHash],
      dataModelId,
      grouping,
    ] as const;
  },
  associateValues: (
    dashboardId: string,
    widgetId: string,
    viewId: string,
    reqBody: ReportRequestBody,
  ) => {
    return [
      'associate-values',
      ...reportQK.request({ dashboardId, widgetId, viewId, reqBody }),
    ];
  },
  aggregationResult: (
    dashboardId: string,
    widgetId: string,
    viewId: string,
    reportRequestBodyJSON: string,
    grouping: string,
    columnAggregationKey: string,
    columnAggregationJSON: string,
  ) => {
    return [
      ...reportQK.view(
        dashboardId,
        widgetId,
        viewId,
        reportRequestBodyJSON,
        grouping,
      ),
      columnAggregationKey,
      columnAggregationJSON,
    ] as const;
  },
  footerNormalAggregations: (
    dashboardId: string,
    widgetId: string,
    viewId: string,
    reportRequestBodyJSON: string,
    grouping: string,
    filterItem: FilterItem[],
    columnsAggregation: ColumnAggregate,
  ) => {
    return [
      ...reportQK.view(
        dashboardId,
        widgetId,
        viewId,
        reportRequestBodyJSON,
        grouping,
      ),
      'footerNormalAggregation',
      'filterBy',
      filterItem,
      'aggregatedBy',
      columnsAggregation,
    ] as const;
  },
} as const;

export const reportQueriesPredicate = ({
  teamId,
  dataModelId,
  datasetId,
  dashboardId,
  widgetId,
  viewId,
}: {
  teamId?: string;
  dataModelId?: string;
  datasetId?: string;
  dashboardId?: string;
  widgetId?: string;
  viewId?: string;
}) => {
  const reportQueryKey = reportQK.prefix({
    teamId,
    dataModelId,
    datasetId,
    dashboardId,
    widgetId,
    viewId,
  });

  return (query: Query) => {
    const matchKey = reportQueryKey.every((keyItem, index) => {
      if (!query.queryKey) return false;

      if (
        typeof keyItem === 'string' &&
        typeof query.queryKey[index] === 'string'
      ) {
        return query.queryKey[index] === keyItem;
      }

      if (
        typeof keyItem === 'object' &&
        typeof query.queryKey[index] === 'object' &&
        keyItem !== null &&
        query.queryKey[index] !== null
      ) {
        const allFieldMatch = Object.keys(keyItem).every(
          (key) =>
            keyItem[key] ===
            (query.queryKey[index] as Record<string, string>)[key],
        );
        return allFieldMatch;
      }

      return false;
    });

    return matchKey;
  };
};

export const goalMetricQK = {
  all: ({
    teamId,
    dataModelId,
    datasetId,
  }: {
    teamId: string;
    dataModelId: string;
    datasetId: string;
  }) => [teamId, dataModelId, datasetId, 'goal-metrics'],
  detail: ({
    metricId,
    ...rest
  }: {
    teamId: string;
    dataModelId: string;
    datasetId: string;
    metricId: string;
  }) => [...goalMetricQK.all({ ...rest }), metricId],
} as const;

export const subscriptionQK = {
  all: ({ teamId }: { teamId: string }) => ['subscriptions', teamId],
  byDashboard: ({
    teamId,
    dashboardId,
  }: {
    teamId: string;
    dashboardId: string;
  }) => [...subscriptionQK.all({ teamId }), dashboardId],
  detail: ({
    subscriptionId,
    dashboardId,
    teamId,
  }: {
    teamId: string;
    dashboardId?: string;
    subscriptionId: string;
  }) =>
    dashboardId
      ? [...subscriptionQK.byDashboard({ dashboardId, teamId }), subscriptionId]
      : [...subscriptionQK.all({ teamId }), subscriptionId],
} as const;

export const alertQK = {
  all: ({ teamId }: { teamId: string }) => ['alerts', teamId],
  byWidget: ({
    teamId,
    dashboardId,
    widgetId,
  }: {
    teamId: string;
    dashboardId?: string;
    widgetId?: string;
  }) => [...alertQK.all({ teamId }), dashboardId, widgetId],
  detail: ({
    alertId,
    dashboardId,
    widgetId,
    teamId,
  }: {
    teamId: string;
    dashboardId?: string;
    widgetId?: string;
    alertId: string;
  }) =>
    dashboardId
      ? [...alertQK.byWidget({ dashboardId, widgetId, teamId }), alertId]
      : [...alertQK.all({ teamId }), alertId],
} as const;

export const commonQK = {
  main: 'common',
  defaultLinks: ['common', 'defaultLinks'],
  defaultTeamLink: (teamId: string) => [
    ...commonQK.defaultLinks,
    'of-team',
    teamId,
  ],
} as const;

export const connectionDsQK = {
  main: ['connection-ds'],
  params: (params: Record<string, any>) => [...connectionDsQK.main, params],
  id: (id: string) => [...connectionDsQK.main, id],
} as const;

export const transformationQK = {
  main: ['transformation'],
  collections: (teamId: string, searchKeyword?: string) => [
    ...transformationQK.main,
    'team=' + teamId,
    'collections',
    ...(searchKeyword != null ? ['search_by', searchKeyword] : []),
  ],
  collectionById: (id: string) => [...transformationQK.main, id],
};

export const exportsQK = {
  main: 'exports',
  verify: function (teamId: string) {
    return [teamId, this.main, 'verify'];
  },
  getById: function (teamId: string, exportId: string) {
    return [teamId, this.main, exportId];
  },
  filter: function (teamId: string, params: ExportFilterParams) {
    const sourceKey = [teamId, this.main, params.source_type];
    if (params.source_type === 'DATA_MODEL') {
      return sourceKey.concat([params.data_model_id, params.dataset_id]);
    }

    return sourceKey;
  },
};

export const dashboardTemplateQK = {
  _main: 'dashboard-template',
  getAll(params: DashboardTemplateParams) {
    return [
      this._main,
      params.team_id,
      params.search_keyword,
      params.size,
      params.offset,
      params.usecases,
    ];
  },
};
