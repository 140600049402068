import { Interface } from 'readline';
import z from 'zod';

import { zLocaleSchema } from '@/common.type';

import { zDateSchema } from './common';

export const zTeamPermissions = z.enum(['OWNER', 'MEMBER']);
export type TeamPermissions = z.infer<typeof zTeamPermissions>;

export const zDashboardPermissions = z.enum(['OWNER', 'VIEWER', 'EDITOR']);
export type DashboardPermissions = z.infer<typeof zDashboardPermissions>;

export const zPreferences = z.object({
  has_watched_introduction: z.boolean().nullish(),
  sidebar: z
    .object({
      connection_page_width: z.number().nullish(),
      dashboard_page_width: z.number().nullish(),
      transformation_page_width: z.number().nullish(),
    })
    .nullish(),
});

export type UserPreference = z.infer<typeof zPreferences>;

export const UserDtoSchema = z.object({
  id: z.string(),
  email: z.string(),
  username: z.string(),
  avatar_url: z.string().nullish(),
  phone_number: z.string().nullish(),
  company_name: z.string().nullish(),
  language: zLocaleSchema.nullish(),
  created_at: zDateSchema.nullish(),
  updated_at: zDateSchema.nullish(),
  permission: zTeamPermissions.nullish(),
  preferences: zPreferences.nullish(),
  role: z.string().nullish(),
  used_to_join_team: z.boolean().nullish(),
});

export type UserDto = z.infer<typeof UserDtoSchema>;

// NOTE: This is user within inviter
export interface MemberDto extends UserDto {
  inviter?: Partial<UserDto>;
}

export const zDashboardUserDto = UserDtoSchema.extend({
  permission: zDashboardPermissions.nullish(),
  type: z.literal('MEMBER'),
});

export const zDashboardGuestUserDto = z.object({
  type: z.literal('GUEST'),
  email: z.string(),
  permission: zDashboardPermissions.nullish(),
});

export const zTeamGuestUserDto = z.object({
  id: z.string(),
  team_id: z.string(),
  email: z.string(),
  inviter: UserDtoSchema,
  member_id: z.string().optional(),
});

export type DashboardUserDto = z.infer<typeof zDashboardUserDto>;

export type DashboardGuestUserDto = z.infer<typeof zDashboardGuestUserDto>;

export type TeamGuestUserDto = z.infer<typeof zTeamGuestUserDto>;
