import type {
  ApiAdapterRequestHeaders,
  RequestManager,
} from '@/adapters/api/__base';
import type { WidgetDto } from '@/models/widget';

import { BaseApiAdapterClass } from '@/adapters/api/__base';
import { NO_PERMISSION_ACCESS_TEAM_API_ERROR } from '@/initializers/AppErrorBoundary';
import { WidgetDtoSchema } from '@/models/widget';
import { serializeArrayData, serializeData } from '@/utils/data';
import { isUuid } from '@/utils/string';

import {
  Subscription,
  SubscriptionPayload,
  zSubscription,
} from '../../models/actions/subscription';
import { clientHttp, serverHttp } from './axios';

class SubscriptionApiAdapterClass extends BaseApiAdapterClass {
  constructor(rm: RequestManager) {
    super(rm);
  }

  private clone(): SubscriptionApiAdapterClass {
    return new SubscriptionApiAdapterClass(this.requestManager);
  }

  buildWithRequestHeaders(
    headers: ApiAdapterRequestHeaders,
  ): SubscriptionApiAdapterClass {
    const cloned = this.clone();
    cloned.setRequestHeaders(headers);
    return cloned;
  }

  async create(
    teamId: string,
    payload: SubscriptionPayload,
  ): Promise<Subscription | null> {
    const url = `/teams/${teamId}/subscriptions`;

    const data = await this.request<Subscription, SubscriptionPayload>(
      url,
      'post',
      payload,
    );

    return serializeData(data, zSubscription);
  }

  async update(
    teamId: string,
    subscriptionId: string,
    payload: Partial<Subscription>,
  ): Promise<Subscription | null> {
    const url = `/teams/${teamId}/subscriptions/${subscriptionId}`;

    const data = await this.request<Subscription, Partial<Subscription>>(
      url,
      'patch',
      payload,
    );

    return serializeData(data, zSubscription);
  }

  async delete(teamId: string, subscriptionId: string): Promise<unknown> {
    const url = `/teams/${teamId}/subscriptions/${subscriptionId}`;

    const data = await this.request<unknown>(url, 'delete');

    return data;
  }

  async getAll(teamId: string, dashboardId?: string): Promise<Subscription[]> {
    const data = await this.get<Subscription[]>(
      `/teams/${teamId}/subscriptions/_list`,
      {
        dashboard_id: dashboardId,
      },
    );

    return serializeArrayData(data ?? [], zSubscription);
  }

  async getDetail({
    subscriptionId,
    teamId,
  }: {
    subscriptionId: string;
    teamId: string;
  }): Promise<Subscription | null> {
    const data = await this.get<Subscription | null>(
      `/teams/${teamId}/subscriptions/${subscriptionId}`,
    );

    if (!data) return null;

    return serializeData(data, zSubscription);
  }

  async subscribe({
    teamId,
    subscriptionId,
  }: {
    teamId: string;
    subscriptionId: string;
  }): Promise<unknown> {
    const url = `/teams/${teamId}/subscriptions/${subscriptionId}/_subscribe_email`;

    const data = await this.request<unknown>(url, 'post');

    return data;
  }

  async unsubscribe({
    teamId,
    subscriptionId,
    payload,
  }: {
    teamId: string;
    subscriptionId: string;
    payload: string;
  }): Promise<unknown> {
    const url = `/teams/${teamId}/subscriptions/${subscriptionId}/_unsubscribe_email`;

    const data = await this.request<unknown, string>(url, 'post', payload);

    return data;
  }

  async unsubscribeWithToken({ token }: { token: string }): Promise<unknown> {
    const url = `/subscriptions/_unsubscribe_email`;

    const data = await this.request<unknown, string>(url, 'post', token);

    return data;
  }

  async sendTestEmail({
    teamId,
    payload,
  }: {
    teamId: string;
    payload: SubscriptionPayload;
  }): Promise<unknown> {
    const url = `/teams/${teamId}/subscriptions/_test`;

    const data = await this.request<unknown, SubscriptionPayload>(
      url,
      'post',
      payload,
    );

    return data;
  }

  async getPreviewContent({
    teamId,
    payload,
  }: {
    teamId: string;
    payload: SubscriptionPayload;
  }): Promise<string | null> {
    const url = `/teams/${teamId}/subscriptions/_preview_content`;

    const data = await this.request<string | null, SubscriptionPayload>(
      url,
      'post',
      payload,
    );

    return data;
  }

  async testExistingSubscription({
    teamId,
    subscriptionId,
  }: {
    teamId: string;
    subscriptionId: string;
  }): Promise<unknown> {
    const url = `/teams/${teamId}/subscriptions/${subscriptionId}/_test`;

    const data = await this.request<unknown>(url, 'post');

    return data;
  }
}

const SubscriptionApi = {
  onBrowser: () => new SubscriptionApiAdapterClass(clientHttp),
  // onBrowserBigNumber: () => new SubscriptionApiAdapterClass(bigNumberHttp),
  onServer: () => new SubscriptionApiAdapterClass(serverHttp),
};

export default SubscriptionApi;
