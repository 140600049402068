import React from 'react';

import Image from 'next/image';
import { useRouter } from 'next/navigation';

import { Divider } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { type FallbackProps } from 'react-error-boundary';

import { AppButtonV2 } from '@/components/Button';
import { useTeamsQuery } from '@/layouts/navbar/use-team';
import { AppRoutes } from '@/routes';
import TeamFill from '@static/assets/empty-page/team-fill.png';
import LogoCigro2WithText from '@static/assets/logo-with-text.png';

import { BackToDashboardButton } from './components/BackToDashboardButton';

export const TeamPermissionErrorPage: React.FC<
  Omit<FallbackProps, 'error'>
> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation('common', { keyPrefix: 'error-page' });

  return (
    <Stack width="100%" height="100%">
      <Container maxWidth="md" sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          //
        >
          <Stack width="350px" gap="16px" alignItems="center">
            <Stack
              p="6px"
              borderRadius="99px"
              bgcolor="neutralV2.6"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)"
              sx={{ width: 'fit-content' }}
            >
              <Image
                src={TeamFill}
                height="18"
                width="18"
                alt="No team permission icon"
                priority
              />
            </Stack>
            <Stack gap="12px">
              <Typography variant="h2" color="neutralV2.0" textAlign="center">
                {t('access-denied!')}
              </Typography>
              <Typography
                variant="body2"
                textAlign="center"
                color="neutralV2.2"
              >
                Your team is already existed
              </Typography>
            </Stack>

            <BackToDashboardButton resetErrorBoundary={resetErrorBoundary} />

            <Divider
              component="span"
              color="neutralV2.5"
              sx={{ width: '100%' }}
            />
            <Stack mt="24px">
              <Image
                src={LogoCigro2WithText}
                height="30"
                width="96"
                alt="Logo Cigro 2 with text"
                priority
                style={{ opacity: 0.4 }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
