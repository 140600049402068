'use client';

import dayjs from 'dayjs';

import { DashboardFilterItem } from '@/adapters/api/dashboard';
import { TransformationDataModels } from '@/adapters/api/data-transformation';
import { exhaustiveTypeCheck } from '@/utils/type';

import { ISO_DATE_FORMAT } from '../constants';
import {
  AdvancedFilter,
  FilterItem,
  TimeSeriesPeriod,
  ViewConfig,
} from '../models/view';
import {
  fromC2pConfigToPastRange,
  getDateTimeFromDateFilter,
  getMonth,
  getQuarterStringFromDate,
  getWeekStart,
  getYear,
  RANGED_DATES_FN_MAPPER,
} from '../utils/date';
import { ReportRequestBody } from './types';

export const convertDFilterToRFilter = (
  filter: DashboardFilterItem,
): AdvancedFilter => {
  return {
    name: filter.name,
    is_active: filter.is_active,
    logical_operator: filter.logical_operator,
    column_filters: filter.column_filters.map((item) => item.column_filter),
  };
};

export function getRangeDatesFromFilter(
  dateFilter: FilterItem,
  periodicity: TimeSeriesPeriod,
): string[] {
  if (dateFilter.column_type !== 'DATETIME') return [];

  const {
    from_time: fromTime,
    to_time: toTime,
    first_day_of_week,
  } = getDateTimeFromDateFilter(dateFilter);
  const weekStart = getWeekStart(first_day_of_week);

  return RANGED_DATES_FN_MAPPER[periodicity](
    dayjs(fromTime).format(ISO_DATE_FORMAT),
    dayjs(toTime).format(ISO_DATE_FORMAT),
    weekStart,
  );
}

export function getPastRangeDatesFromFilter(
  dateFilter: FilterItem,
  periodicity: TimeSeriesPeriod,
  c2pConfig: Exclude<ViewConfig['compare_with_past'], null | undefined>,
): string[] {
  if (dateFilter.column_type !== 'DATETIME') return [];

  const {
    from_time: fromTime,
    to_time: toTime,
    first_day_of_week,
  } = getDateTimeFromDateFilter(dateFilter);

  const weekStart = getWeekStart(first_day_of_week);

  const { fromDate: pastFromTime, toDate: pastToTime } =
    fromC2pConfigToPastRange({
      c2pConfig,
      periodicity,
      currentStartDate: fromTime,
      currentEndDate: toTime,
      weekStart,
    });

  if (!pastFromTime || !pastToTime) return [];

  const pastRangeDate = RANGED_DATES_FN_MAPPER[periodicity](
    pastFromTime,
    pastToTime,
    weekStart,
  );

  return pastRangeDate;
}

export function getReportQKFromBody(body: ReportRequestBody | null) {
  if (!body) {
    return [];
  }
  return [
    body.data.source.name,
    body.data.source.columns,
    body.data.source.custom_formula_columns,
    body.data.loader.filters,
    body.data.loader.first_day_of_week,
    body.data.loader.groupBy,
    body.data.loader.sort,
    body.data.loader.pagination,
  ];
}

export const getDatetimeStrByPeriodicity = (
  periodicity: TimeSeriesPeriod,
  lookupDate: string,
) => {
  switch (periodicity) {
    case 'DAILY':
    case 'WEEKLY':
      return lookupDate;
    case 'MONTHLY':
      return getMonth(lookupDate);
    case 'QUARTERLY':
      return getQuarterStringFromDate(lookupDate);
    case 'YEARLY':
      return getYear(lookupDate);
    default:
      return exhaustiveTypeCheck(periodicity);
  }
};

export const dataModelColumnsSelector = (
  data: TransformationDataModels | null,
) => {
  return (data?.columns || [])?.map((col) => ({
    column_name: col.property,
    column_type: col.type,
  }));
};
