'use client';

import type { PropsWithChildren } from 'react';

import React, { useEffect } from 'react';

import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';

import { ApiError } from '@/api-error';
import useNetworkStatus from '@/hooks/use-network-status';

import { DashboardPermissionErrorPage } from './error-page/DashboardPermissionErrorPage';
import { ExpiredSessionErrorPage } from './error-page/ExpiredSessionErrorPage';
import { NetworkErrorPage } from './error-page/NetworkErrorPage';
import { NotFoundErrorPage } from './error-page/NotFoundErrorPage';
import { TeamPermissionErrorPage } from './error-page/TeamPermissionErrorPage';
import { UnknownErrorPage } from './error-page/UnknownErrorPage';

const NOT_AUTHORIZED = 401;
const UNPROCESSABLE_CONTENT = 422;
export const FORBIDDEN_CODE = 403;

const GENERAL_ERR_CODE = '40000';
export const INVALID_JWT_TOKEN_ERR_CODE = '40002';
export const NO_PERMISSION_ACCESS_TEAM_ERR_CODE = '41001';
export const NO_PERMISSION_ACCESS_DASHBOARD_ERR_CODE = '42001';
export const EXPIRED_JWT_TOKEN_ERR_CODE = '40003';
export const NO_PERMISSION_ACCESS_WIDGET_ERR_CODE = '43001';

export const NETWORK_ERROR = 'ERR_INTERNET_DISCONNECTED';
export const NEXT_NOT_FOUND_ERROR = 'NEXT_NOT_FOUND';

export const NO_PERMISSION_ACCESS_TEAM_API_ERROR = new ApiError(null, {
  detail: 'from-client-side',
  error_code: Number(NO_PERMISSION_ACCESS_TEAM_ERR_CODE),
});

export const NO_PERMISSION_ACCESS_DASHBOARD_API_ERROR = new ApiError(null, {
  detail: 'from-client-side',
  error_code: Number(NO_PERMISSION_ACCESS_DASHBOARD_ERR_CODE),
});

export class AppPageNotFoundError extends Error {
  private readonly __callbackUrl?: string;

  constructor(callbackUrl?: string, options?: ErrorOptions) {
    super('[cigro2.0] Page not found ~', options);
    this.__callbackUrl = callbackUrl;
  }

  get callbackUrl() {
    return this.__callbackUrl;
  }
}

const AppErrorUi: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  // NOTE: page not found
  if (error instanceof AppPageNotFoundError) {
    return <NotFoundErrorPage resetErrorBoundary={resetErrorBoundary} />;
  }

  if (error instanceof ApiError && error.statusCode === NOT_AUTHORIZED) {
    return <ExpiredSessionErrorPage />;
  }

  if (
    error instanceof ApiError &&
    error.statusCode === 403 &&
    error.errorCode === NO_PERMISSION_ACCESS_TEAM_ERR_CODE
  ) {
    return <TeamPermissionErrorPage resetErrorBoundary={resetErrorBoundary} />;
  }

  if (
    error instanceof ApiError &&
    (error.statusCode === 403 ||
      error.errorCode === NO_PERMISSION_ACCESS_DASHBOARD_ERR_CODE ||
      error.errorCode === NO_PERMISSION_ACCESS_WIDGET_ERR_CODE)
  ) {
    return (
      <DashboardPermissionErrorPage resetErrorBoundary={resetErrorBoundary} />
    );
  }

  if (
    error instanceof ApiError &&
    error.errorCode === GENERAL_ERR_CODE &&
    error.statusCode === UNPROCESSABLE_CONTENT
  ) {
    return <NotFoundErrorPage resetErrorBoundary={resetErrorBoundary} />;
  }

  if (error?.originError === NETWORK_ERROR) {
    return <NetworkErrorPage resetErrorBoundary={resetErrorBoundary} />;
  }

  // NOTE: unknown error
  return <UnknownErrorPage resetErrorBoundary={resetErrorBoundary} />;
};

export const NetworkErrorBoundary: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isOnline = useNetworkStatus();

  if (typeof window !== 'undefined' && !isOnline)
    throw new ApiError(NETWORK_ERROR, {
      error_code: 423,
      detail: 'Network error!',
    });

  return <>{children}</>;
};

export const ErrorBoundaryWithQuery: React.FC<
  PropsWithChildren<{
    fallbackRender: (props: FallbackProps) => React.ReactNode;
  }>
> = ({ children, fallbackRender }) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary fallbackRender={fallbackRender} onReset={reset}>
          <NetworkErrorBoundary>{children}</NetworkErrorBoundary>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export const AppErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorBoundaryWithQuery fallbackRender={AppErrorUi}>
      {children}
    </ErrorBoundaryWithQuery>
  );
};

/**
 * Next.js "error" page props
 */
type AppErrorPageProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export function AppErrorPage({ error, reset }: AppErrorPageProps) {
  useEffect(() => {
    console.error(error);
  }, [error]);
  return <AppErrorUi error={error} resetErrorBoundary={reset} />;
}
