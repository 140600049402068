import { IExportSchedule } from '@/components/ScheduleConfig';
import { DAYS_OF_WEEK } from '@/constants';

import {
  ConnectionDS,
  CsvColumn,
  CsvColumnType,
  DefaultConnectionFormProps,
  IDefaultConnectionConfig,
} from './types';

export const CSV_TYPE_OPTIONS: { label: String; value: CsvColumnType }[] = [
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
  { label: 'Boolean', value: 'boolean' },
  { label: 'Date', value: 'date' },
  { label: 'Email', value: 'email' },
  { label: 'Credit card', value: 'credit_card' },
  { label: 'IP address', value: 'ip' },
  { label: 'Phone number ', value: 'phone_number' },
  { label: 'Regex ', value: 'regex' },
  { label: 'Url ', value: 'url' },
  { label: 'List ', value: 'list' },
  { label: 'Dependent list', value: 'dependent_list' },
  { label: 'Dynamic list ', value: 'dynamic_list' },
  { label: 'Dependent dynamic list', value: 'dependent_dynamic_list' },
  { label: 'Multiselect dynamic list ', value: 'multiselect_dynamic_list' },
  { label: 'Date time ', value: 'datetime' },
];

export const SHARED_OPTION_SX = {
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  width: '100%',
  gap: '12px',
  padding: '16px',
  borderRadius: 1,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'neutral.6',
  },
};

export const CATEGORY_OPTIONS = [
  {
    title: 'all-source-option-label',
    iconName: 'ic_all_categories_18',
    value: '',
  },
  {
    title: 'database-option-label',
    iconName: 'ic_database_categories_18',
    value: 'Database',
  },
  {
    title: 'marketing-option-label',
    iconName: 'ic_marketing_categories_18',
    value: 'Marketing',
  },
  {
    title: 'api-option-label',
    iconName: 'ic_api_sales_categories_18',
    value: 'API',
  },
  {
    title: 'sales-option-label',
    iconName: 'ic_api_sales_categories_18',
    value: 'Sales',
  },
  {
    title: 'productivity-option-label',
    iconName: 'ic_productivity_categories_18',
    value: 'Productivity',
  },
  {
    title: 'file-option-label',
    iconName: 'ic_file_categories_18',
    value: 'File',
  },
  {
    title: 'security-option-label',
    iconName: 'ic_security_categories_18',
    value: 'Security',
  },
  {
    title: 'support-option-label',
    iconName: 'ic_support_categories_18',
    value: 'Support',
  },
  {
    title: 'engineering-option-label',
    iconName: 'ic_engineering_categories_18',
    value: 'Engineering',
  },
  {
    title: 'events-option-label',
    iconName: 'ic_events_categories_18',
    value: 'Events',
  },
  {
    title: 'finance-option-label',
    iconName: 'ic_finance_categories_18',
    value: 'Finance',
  },
  {
    title: 'function-option-label',
    iconName: 'ic_function_categories_18',
    value: 'Function',
  },
  {
    title: 'human-resources-option-label',
    iconName: 'ic_human_resources_categories_18',
    value: 'HumanResources',
  },
  {
    title: 'upload-file-option-label',
    iconName: 'ic_human_resources_categories_18',
    value: 'UploadFile',
  },
  {
    title: 'other-option-label',
    iconName: 'ic_human_resources_categories_18',
    value: 'Other',
  },
] as const;

export const CSV_CONNECTION: ConnectionDS = {
  id: 'upload-file-id',
  id_in_provider: 'upload-file-id',
  name: 'CSV file',
  type: 'UploadFile',
  is_active: true,
  icon_url:
    'https://d2mz7op7kf3q1g.cloudfront.net/team-avatar/9646c1d2-9aae-4edf-bf86-69bfd8be2a4c/18ab5708-bdfa-4be6-bf14-9b61c51f05e4',
  docs_url: '',
  erd_url: '',
  created_at: '2023-09-11T04:49:48.286261+00:00',
  updated_at: '2024-02-15T06:43:12.099224+00:00',
};

export const DEFAULT_COLUMNS: CsvColumn[] = [
  {
    column_name: '',
    column_type: 'text',
  },
  {
    column_name: '',
    column_type: 'text',
  },
];

export const LIST_SYNCRONIZE_POWERD_BY = [
  'Fivetran',
  'Cigro',
  'CigroEcommerce',
  'CigroCustomDashboard',
];

export const COLUMN_TYPE_ICON = {
  DATETIME: 'ic_calendar_16',
  DATE: 'ic_calendar_16',
  TEXT: 'ic_filters_24_typography',
  JSON: 'braces-line',
  NUMBER: 'numbers',
  BOOLEAN: 'ic_boolean',
  ARRAY: 'brackets-line',
};

export const NOT_AUTHORIZED = 'NOT_AUTHORIZED';

export const BINARY_BASE = 1024;
export const KB = BINARY_BASE;
export const MB = KB * KB;
export const GB = MB * MB;

export const CSV_BOX_DATE_TIME_ALLOW_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const CONNECTION_NAME = 'CONNECTION_NAME';

export const CONNECTION_INPUT_VALUE_MAPPER = {
  PRIVATE_INPUT: '',
  PUBLIC_INPUT: '',
  CHECKBOX: false,
  SELECTABLE: '',
  PUBLIC_LIST: [],
};

export const DEFAULT_CONNECTION_CONFIG: IDefaultConnectionConfig = {
  periodType: 'AM',
  periodTime: 'every-day',
  hour: 9,
  minute: 0,
};
export const DEFAULT_VALUES: DefaultConnectionFormProps = {
  CONNECTION_NAME: null,
};

export const DEFAULT_SCHEDULE: IExportSchedule = {
  type: 'DAILY',
  intervalHour: 24,
  daysOfWeek: [...DAYS_OF_WEEK],
  startTime: 9,
};

export const CONTACT_MAIL = 'seongkyu@cigro.io';
