import { z } from 'zod';

import { zColumnType } from '@/models/view';

const zCollectionCategory = z.enum(['COLLECTION', 'DATA_MODEL']);

export type CollectionCategory = z.infer<typeof zCollectionCategory>;

export const zCollectionDataModelColumn = z.object({
  property: z.string(),
  description: z.string().nullish(),
  type: zColumnType,
  // original_type: zTemplateColumnType,
});

export type CollectionDataModelColumn = z.infer<
  typeof zCollectionDataModelColumn
>;

export type DuplicateDataModelPayload = {
  id?: string;
  parent_collection_id: string;
};
