'use client';

import React, { useEffect } from 'react';

import Image from 'next/image';

import { CONTACT_MAIL } from '@cigro/data-connection/constants';
import { Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { Trans, useTranslation } from 'next-i18next';
import { type FallbackProps } from 'react-error-boundary';

import { AppButtonV2 } from '@/components/Button';
import { toastSuccess } from '@/utils/notification';
import AlarmWarningFill from '@static/assets/empty-page/alarm-warning-fill.png';

import { BackToDashboardButton } from './components/BackToDashboardButton';

export const UnknownErrorPage: React.FC<Omit<FallbackProps, 'error'>> = ({
  resetErrorBoundary,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'error-page' });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleRouteChange = () => {
      resetErrorBoundary();
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [resetErrorBoundary]);

  return (
    <Stack id="endash-crash-page" height="100vh">
      <Container maxWidth="md" sx={{ flexGrow: 1 }}>
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"

          //
        >
          <Stack gap="16px" width="350px" alignItems="center">
            <Stack
              p="6px"
              borderRadius="99px"
              bgcolor="semantic.negative10"
              boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)"
              sx={{ width: 'fit-content' }}
            >
              <Image
                src={AlarmWarningFill}
                height="18"
                width="18"
                alt="notfound icon"
                priority
              />
            </Stack>

            <Stack gap="8px">
              <Typography variant="h2" color="neutralV2.0" textAlign="center">
                {t('page-crashed')}
              </Typography>
              <Typography
                variant="body2"
                color="neutralV2.2"
                textAlign="center"
              >
                {t('sorry,-something-went-wrong-with-this-page.')}
              </Typography>
              <Typography
                variant="body3"
                color="neutralV2.2"
                textAlign="center"
              >
                {t('please-try-again-or')}&nbsp;
                <u
                  onClick={() => {
                    navigator.clipboard.writeText(CONTACT_MAIL);
                    toastSuccess(
                      <>
                        <Trans i18nKey="page-team-connection.send-email-to" />
                        &nbsp;{CONTACT_MAIL}
                      </>,
                    );
                  }}
                  style={{
                    color: '#6713EF',
                    cursor: 'pointer',
                  }}
                >
                  {t('send-us-a-message')}
                </u>
              </Typography>
            </Stack>

            <Stack
              width="100%"
              alignItems="center"
              justifyContent="center"
              gap="8px"
              //
            >
              <AppButtonV2
                variant="contain"
                color="primary"
                onClick={() => {
                  if ('caches' in window) {
                    caches.keys().then((names) => {
                      names.forEach((name) => {
                        caches.delete(name);
                      });
                    });
                  }
                  resetErrorBoundary();
                }}
                style={{ width: '100%', justifyContent: 'center' }}
              >
                {t('retry')}
              </AppButtonV2>

              <BackToDashboardButton resetErrorBoundary={resetErrorBoundary} />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
