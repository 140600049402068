import { JSONContent } from 'novel';
import { z } from 'zod';

import { zDateSchema } from '@/models/common';
import { UserDtoSchema, zDashboardPermissions } from '@/models/user';

const zTeamSubscriptionStatus = z.enum([
  'FREE_TRIAL',
  'ACTIVE',
  'IN_ACTIVATED',
  'CANCELED',
  'PAUSED',
  'PAST_DUE',
  'UNPAID',
  'EXPIRED',
]);

export const TeamDtoSchema = z.object({
  id: z.string(),
  owner_id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  avatar_url: z.string().nullable(),
  plan: z.string().nullable(),
  total_members: z.number().int().nullable(),
  team_members: z.array(UserDtoSchema).optional(),
  created_at: zDateSchema,
  updated_at: zDateSchema,
  permission: zDashboardPermissions.nullish(),
  is_payment_exempted: z.boolean().nullish(),
  payment_subscription_id: z.string().nullish(),
  subscription_status: zTeamSubscriptionStatus.default('FREE_TRIAL'),
  eligible_to_use: z.boolean().nullish(),
  is_guest: z.boolean().nullish(),
  dag_config: z
    .object({
      notes: z.record(z.any()),
    })
    .nullish(),

  company_id: z.string().nullish(),
});

export type TeamDto = z.infer<typeof TeamDtoSchema>;
