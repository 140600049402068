import type { SxProps, Theme } from '@mui/material/styles';

import { forwardRef, MouseEventHandler } from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

import { spreadMuiSxes } from '@/utils/styles';

import AppIcon from '../Icon';
import { AppButtonV2, AppButtonV2Props } from '.';

type Props = {
  buttonColor?: 'primary' | 'danger' | 'tertiary';
  iconName: string;
  iconSize: number;
  iconColor?: string;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  tooltipProps?: Omit<TooltipProps, 'children'>;
} & Omit<AppButtonV2Props, 'color' | 'children'>;

const AppIconButton = forwardRef<HTMLButtonElement, Props>(function IconButton(
  {
    iconName,
    iconSize,
    iconColor,
    buttonVariant,
    buttonColor,
    onClick,
    sx,
    disabled,
    tooltipProps,
    ...buttonProps
  },
  ref,
) {
  //
  if (tooltipProps)
    return (
      <Tooltip {...tooltipProps}>
        <AppButtonV2
          ref={ref}
          disabled={disabled}
          slots={{ root: AppIcon }}
          role="button"
          variant="text"
          color={buttonColor}
          name={iconName}
          onClick={onClick}
          sx={[
            {
              p: 0,
              borderRadius: '4px',
              fontSize: iconSize + 'px',
              color: iconColor,
            },
            ...spreadMuiSxes(sx),
          ]}
          {...buttonProps}
        />
      </Tooltip>
    );

  return (
    <AppButtonV2
      ref={ref}
      disabled={disabled}
      role="button"
      color={buttonColor}
      onClick={onClick}
      startIcon={<AppIcon name={iconName} size={iconSize} color="inherit" />}
      sx={[
        {
          p: 0,
          borderRadius: '4px',
          fontSize: iconSize + 'px',
          color: iconColor,
        },
        ...spreadMuiSxes(sx),
      ]}
      variant={buttonVariant}
      {...buttonProps}
    />
  );
});

export default AppIconButton;
