import { z } from 'zod';

import {
  zAggregateItem,
  zGrouping,
  zGroupItem,
  zTimeSeriesOption,
} from './common';
import { zCompatibleCommonConfig } from './compatibility';

export const zRankChartConfig = z.object({
  view_type: z.literal('RANK_CHART'),
  time_series: zTimeSeriesOption.nullish(),
  top_n: z.number().min(1).max(10).nullish(),
  label_size: z.number().default(6).nullish(),
  rotate_label: z.boolean().default(false).nullish(),
  show_label: z.boolean().nullish(),
});

export const zRankChartFullConfig =
  zCompatibleCommonConfig.merge(zRankChartConfig);

export type RankChartFullConfig = z.infer<typeof zRankChartFullConfig>;

export const zRankChartRenderingConfig = zRankChartFullConfig.merge(
  z.object({
    grouping: zGrouping.merge(
      z.object({
        columns: z.array(zGroupItem).min(1, {
          message: 'rank-chart-config-grouping-columns-length-min-1',
        }),
        sub_aggregates: z.array(zAggregateItem).min(1, {
          message: 'rank-chart-config-grouping-sub_aggregates-length-min-1',
        }),
      }),
    ),
  }),
);
export type RankChartConfig = z.infer<typeof zRankChartRenderingConfig>;
