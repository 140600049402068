import { FC, forwardRef } from 'react';

import { spreadMuiSxes } from '@/utils/styles';

import AppButtonV2, { AppButtonV2Props } from './AppButtonV2';

const StyledSmallMenuButton: FC<AppButtonV2Props> = forwardRef<
  HTMLButtonElement,
  AppButtonV2Props
>((props, ref) => (
  <AppButtonV2
    slots={{ root: 'span' }}
    variant="text"
    color="tertiary"
    {...props}
    sx={[
      {
        p: 0,
        minWidth: '38px',
        maxWidth: '120px',
        bgcolor: 'inherit',
        borderRadius: '2px',
        gap: 0,
        '&:hover': {
          bgcolor: 'inherit',
        },
      },
      ...spreadMuiSxes(props.sx),
    ]}
    ref={ref}
  />
));

StyledSmallMenuButton.displayName = 'StyledSmallMenuButton';

export default StyledSmallMenuButton;
